.subcats-page__wrapper {
  .category__container {
    display: flex;
    align-items: center;
    padding: 60px 40px;

    .category__image {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 40px;

      img {
        width: 240px;
        height: 240px;
        border-radius: 50%;
      }
    }

    .category__title {
      h1 {
        color: #333333;
        margin-bottom: 24px;
      }

      p {
        font-size: 16px;
        line-height: 19px;
        color: #828282;
      }
    }

    @media (max-width: 800px) {
      flex-direction: column;
      align-items: flex-start;

      .category__image {
        margin-right: 0;
        margin-bottom: 40px;
      }
    }

    @media (max-width: 500px) {
      padding: 60px 20px;
    }
  }

  .subcats__wrapper {
    display: flex;
    flex-wrap: wrap;

    .subcat__container {
      width: 33.3%;
      text-decoration: none;
      // background-image: url("./img/roller-cone-bits.jpg");
      background-position: center;
      background-size: 480px 100%;
      background-repeat: no-repeat;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (min-width: 1440px) {
        height: 300px;
        background-size: cover;
      }

      &:nth-child(1) {
        background-image: url("./img/roller-cone-bits.jpg");
      }

      &:nth-child(2) {
        background-image: url("./img/pdc-bits.jpg");
      }

      &:nth-child(3) {
        background-image: url("./img/impregnated-bits.jpg");
      }

      &:nth-child(4) {
        background-image: url("./img/coring-bits.jpg");
      }

      &:nth-child(5) {
        background-image: url("./img/one-more-subcategory.jpg");
      }

      @media (max-width: 800px) {
        flex-direction: column;
      }

      .subcat__content {
        padding: 40px 50px;
        transition: .2s;

        @media (min-width: 1700px) {
          padding: 51px 50px;
        }

        h2 {
          color: #ffffff;
          transition: .2s;
        }

        p {
          color: #ffffff;

          &:first-of-type {
            margin: 24px 0;

          }
        }

        @media (max-width: 500px) {
          padding: 40px 20px;
        }
      }

      &:hover {
        .subcat__content {
          backdrop-filter: brightness(0.8);
        }
        h2 {
          color: #F74623;
        }
      }

      @media (max-width: 1260px) {
        width: 50%;
        background-size: 630px 100%;
      }

      @media (max-width: 800px) {
        width: 100%;
        background-size: cover;
      }
    }
  }
}