.authentication-page__wrapper.recover-password-page {
  justify-content: flex-start;

  .form__wrapper {
    width: 100%;
    align-items: flex-start;
    margin-left: 100px;
    padding: 100px 0 70px;
    @media (min-width: 1700px) {
      margin-left: 320px;
    }

    @media (max-width: 745px) {
      margin-left: 0;
      padding: 100px 40px 70px;
    }

    @media (max-width: 425px) {
      padding: 100px 20px 70px;
    }

    .info-text {
      margin-top: 20px;
      color: #828282;
    }

    .form__container {
      width: 640px;

      @media (max-width: 745px) {
        width: 100%;
      }
    }
  }

  .info__container {
    .link__container {
      p {
        color: #333333;
      }
      margin-top: 20px;
    }

    button {
      margin: 40px 0;
    }
  }
}