.account-info {
  display: none;

  .account-info__sidebar{
    width: 260px;
    flex-shrink: 0;
  
    @media (max-width: 860px) {
      width: 180px;
  
      .sidebar-item {
        padding-left: 20px;
      }
    }
  }
}

.account-info__sidebar {
  background: rgba(245, 245, 247, 1);
  border: 1px solid #E7E8EA;
  @media (max-width: 575px) {
    border: none;
  }
  .sidebar-item {
    padding: 30px 20px 30px 40px;
    background: #FFFFFF;
    display: flex;
    border: 1px solid #E7E8EA;
    border-right: none;
    box-sizing: border-box;
    align-items: center;
    height: 80px;

    & > div:first-child {
      color: gray;
      margin-right: 15px;
    }

  }



  .dashboard-icon {
    background: url("./img/dasboard-icon.svg");
    min-width: 18px;
    height: 18px;
  }


  .order-icon {
    background: url("./img/orders-icon.svg");
    min-width: 18px;
    height: 20px;
  }

  .quotes-icon {
    background: url("./img/quotes-icon.svg");
    min-width: 15px;
    height: 20px;
  }

  .payments-icon {
    background: url('./img/payments-icon.svg');
    min-width: 20px;
    height: 16px;
  }

  .profile-icon {
    background: url('./img/profile-icon.svg');
    min-width: 18px;
    height: 18px;
  }

  .sidebar-item.selected {
    background: rgba(247, 70, 35, 1);
    border: none;
    color: white;
    .dashboard-icon{
      background: url("./img/dashboard-icon-white.svg");
    }
  }
}

.account-info__content {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #E7E8EA;
  border-left: none;
  border-top: none;
}

.dashboard__title {
  display: flex;
  justify-content: space-between;
  border: 1px solid #E7E8EA;
  border-top:none;
  box-sizing: border-box;
  align-items: center;
  padding: 15px 20px 15px 50px;
  @media(max-width: 690px) {
    flex-wrap: wrap;

  }
  @media(max-width: 350px){
    padding-left: 20px;
  }

  .title__info {
    display: flex;
    align-items: center;

    .large-profile-icon {
      background: url('./img/large-profile-icon.png');
      min-width: 40px;
      height: 40px;
      margin-right: 10px;
    }
    h2{
      color:rgba(51, 51, 51, 1);
    }
  }
}

.dashboard__body{
  padding: 0px 0px 80px 50px;
  h3:first-child{
    margin-top: 30px;
  }
  p{
    margin: 20px 0px;
    color: #828282;
  }
}

.dashboard__statistics-container {
  z-index: 1;
  display: flex;
  align-items: center;

  width: 100%;
  margin-top: 20px;
  flex-wrap: wrap;
  & > div {
    display: flex;
    flex-direction: column;
    width: 180px;
    border-bottom: 1px solid #E7E8EA;
    padding-bottom: 19px;
    margin-right: 15px;
    margin-bottom: 30px;

    @media (max-width: 1100px) {
      display: flex;
      justify-content: space-between;
    }
    @media(max-width: 640px){
      max-width: 150px;
    }

      span:first-child {
        font-weight: 300;
        font-size: 48px;
        line-height: 130%;
        color: #828282;
      }

      span:last-child {
        color: #828282;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
      }

    span.active{
      color:rgba(247, 70, 35, 1);
    }
  }


}

.account-info-mobile{
  display: none;
  .sidebar__body{
    display: none;
  }
  .sidebar__body.active{
    display:block;
  }
}

.white-arrow{
  background: url("./img/arrow-down-white.svg");
  min-width: 11px;
  height: 7px;
  margin-left: auto;
}

@media(min-width: 576px){
  .account-info{
    display: flex;
  }
  .account-info-mobile{
    display: none;
  }
}

@media(max-width: 575px){
  .account-info{
    display: none;
  }
  .account-info-mobile{
    display: block;
  }
}