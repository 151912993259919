.custom {
  display: flex;
  flex-direction: column;
  label {
    margin-bottom: 10px;
    font-size: 16px;
  }
}

.custom-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-checkbox+label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}
.custom-checkbox+label::before {
  content: '';
  display: inline-block;
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #BDBDBD;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 70% 70%;
  cursor: pointer;
}

.custom-checkbox:checked+label::before {
  border-color: #F74623;
  background-color: #F74623;
  background-image: url("./img/checked-checkbox.svg");

  &:hover {
    border-color: #F75E23;
    background-color: #F75E23;
  }
}

.custom-checkbox:disabled+label::before {
  background-color: #e9ecef;
}

 /* для элемента input c type="radio" */
 .custom-radio {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
/* для элемента label связанного с .custom-radio */
.custom-radio+label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}
/* создание в label псевдоэлемента  before со следующими стилями */
.custom-radio+label::before {
  content: '';
  display: inline-block;
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #BDBDBD;
  border-radius: 50%;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}
.custom-radio:checked+label::before {
  border-color: #F74623;
  background-color: #F74623;
  background-image: url("./img/checked-radio.svg");
}
/* стили для радиокнопки, находящейся в состоянии disabled */
.custom-radio:disabled+label::before {
  background-color: #e9ecef;
}

// button
@mixin button {
  border: none;
  padding: 14px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  cursor: pointer;
}

.button-primary {
  @include button;
  background-color: #F74623;
  color: #FFFFFF;

  &:hover {
    background-color: #F75E23;
    transition: .3s;
  }

  &:disabled {
    // background-color: #F9A18F;
    opacity: .5;
    cursor: default;
    &:hover {
      background-color: #F74623;
    }
  }
}

.button-secondary {
  @include button;
  background-color: #F2F2F2;
  color: #333333;
  &:hover {
    color: #F74623;
    transition: .3s;
  }
  &:disabled {
    opacity: .5;
    cursor: default;
    &:hover {
      background-color: #F2F2F2;
      color: #333333;
    }
  }
}

.button-message {
  @include button;
  background-color: #F2F2F2;
  color: #F74623;
  &:hover {
    background-color: #F74623;
    color: #FFFFFF;
    transition: .3s;
  }
  &:disabled {
    opacity: .5;
    cursor: default;
    &:hover {
      background-color: #F2F2F2;
      color: #F74623;
    }
  }
}

.button-primary:active, .button-secondary:active, .button-message:active {
  transition: .1s;
  opacity: 0.5;
}

input {
  outline: none;
}

/* MIXINS */
@mixin hideInput {
  width: 0; 
  height: 0; 
  position: absolute; 
  left: -9999px;
}

/* TOGGLE STYLING */
.toggle {
  box-sizing: border-box;
	font-size: 0;
	display: flex; 
  flex-flow: row nowrap;
	justify-content: flex-start;
  align-items: stretch;

	input {
    @include hideInput;
  }

	input + label {
    cursor: pointer;
		margin: 0;
    z-index: 1;
    margin-top: -0.5px;
    padding: 15px 20px;
    box-sizing: border-box;
		position: relative; 
    display: inline-block;
		border: 1px solid #E7E8EA; 
		font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
		transition: 	border-color .15s ease-out, 
					color .25s ease-out, 
					background-color .15s ease-out,
					box-shadow .15s ease-out;
		
		/* ADD THESE PROPERTIES TO SWITCH FROM AUTO WIDTH TO FULL WIDTH */
		// flex: 0 0 50%; display: flex; justify-content: center; align-items: center;
		/* ----- */
	}
	
	input:checked + label {
		color: #333333;
		border-color: #F74623;
		z-index: 2;
    margin-top: -0.5px;
	}
  input:disabled + label {
		color: #BDBDBD;
		border-color: #E7E8EA;
    border-style: dashed;
    margin-top: -0.5px;
		z-index: 0;
    cursor: default;
	}
}



///////// CUSTOM SELECT /////////////////////////

.custom-select {
  position: relative;
  width: 156px;

  select {
    display: none;
  }

  .select-selected {
    border: 1px solid #F74623;
    color: #333333;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .select-icon {
    width: 11px;
    height: 20px;
    background-image: url("./img/select-arrows.svg");
  }
  
  .select-items div {
    color: #333333;
    padding: 10px 30px;
    font-size: 16px;
    line-height: 30px;
    cursor: pointer;
    user-select: none;
  }

  .select-selected {
    color: #333333;
    padding: 20px 15px;
    cursor: pointer;
    user-select: none;

    &.select-open {
      border-color: #828282;
    }

    &:hover {
      border-color: #BDBDBD;
    }
  }
  
  .select-items {
    position: absolute;
    width: 300px;
    z-index: 4;
    background-color: #ffffff;
    top: 100%;
    left: 0;
    right: 0;
    box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.1);

    >div {
      border-left: 1px solid transparent;
      transition: .3s;
    }
  }
  
  /*hide the items when the select box is closed:*/
  .select-hide {
    display: none;
  }
  
  .select-items div:hover, .same-as-selected {
    border-left: 1px solid #F74623;
    color: #F74623;
  }
}

.custom-input-number {
  display: flex;
  border: 1px solid #E7E8EA;
  width: 174px;
  .t-neutral .spinner__button {
    background: #ffffff;
    color: #333333;
  }
  .t-neutral .spinner__button:hover,
  .t-neutral .spinner__button:focus,
  .t-neutral .spinner__button:active {
    background: #444;
  }


  input[type="number"] {
    -moz-appearance: textfield;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance:none;
    margin: 0;
  }
  input[type="number"]:hover::-webkit-inner-spin-button,
  input[type="number"]:hover::-webkit-outer-spin-button {
   -moz-appearance: none;
    -webkit-appearance: none;
    appearance:none;
    margin:0;
  }

  /* The component (also includes vertical) */

  .spinner {
    margin: 0 0 5em 0;
  }

  .spinner:after {
    clear: both;
    content: "";
    display: table;
  }

  .spinner__button {
    flex-grow: 1;
    cursor: pointer;
    display: block;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    padding: 16px 30px;
    border: none;
    background-color: transparent;

    &:last-of-type {
      color: #F74623;
    }
  }

  .spinner__input {
    width: 35px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: none;
    border: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    border-radius: none;
    box-sizing: border-box;
    display: block;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #333333;
    background-color: transparent;
  }

  .spinner--horizontal .spinner__input {
    padding: 1em 1em 1.05em 1em;
    @media all and (min-width:40em) {
        padding: 1.1em 1em 1.05em 1em;
    }
    }

    .spinner--vertical .spinner__input {
      padding:1.5em 1em 1.5em 1em;
      width:100%;
    }

    .spinner--horizontal .spinner__button {
      float: left;
      width: 25%;
    }

    .spinner--horizontal .spinner__input {
      float: left;
      width: 50%;
    }
}

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #00000036;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

/**************************\
  Demo Animation Style
\**************************/
@keyframes mmfadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}

@keyframes mmfadeOut {
    from { opacity: 1; }
      to { opacity: 0; }
}

@keyframes mmslideIn {
  from { transform: translateY(15%); }
    to { transform: translateY(0); }
}

@keyframes mmslideOut {
    from { transform: translateY(0); }
    to { transform: translateY(-10%); }
}

.micromodal-slide {
  display: none;
}

.micromodal-slide.is-open {
  display: block;
}

.micromodal-slide[aria-hidden="false"] .modal__overlay {
  animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="false"] .modal__container {
  animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__overlay {
  animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__container {
  animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
  will-change: transform;
}