* {
  margin: 0;
  padding: 0;
}

body {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #FBFBFD;
}

$border: 1px solid #E7E8EA;

header {
  border-bottom: $border;
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: fixed;
  z-index: 5;
  background-color: #ffffff;
  height: 80px;
  flex: 1 0 auto;

  > div {
    display: flex;
  }
}

@mixin header-item {
  display: flex;
  align-items: center;
  border-right: $border;
  cursor: pointer;
}

.header-logo__container {
  width: 260px;
  padding-right: 40px;
  @include header-item;
  cursor: default;

  @media (max-width: 1000px) {
    border-right: none;
  }

  @media (max-width: 375px) {
    width: 220px;
    padding-right: 0;
  }

  @media (min-width: 1700px) {
    justify-content: center;
    padding-right: 0;
  }

  .menu-content__container {
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 160px;
    box-shadow: 0px 20px 50px 0px #0000001A;
    z-index: 5;
    top: 81px;
    left: 0px;
    width: 200px;
    padding: 8px 0;

    @media (max-width: 1150px) {
      width: 220px;
    }

    .nav-link.link {
      display: none;
      color: #F74623;
      @media (max-width: 1150px) {
        display: block;
      }
    }

    .nav-link {
      padding-left: 20px;
    }

    .header-categories__container {
      @include header-item;
      padding: 13px 0;
      padding-left: 20px;
      display: none;
      @media (max-width: 1000px) {
        display: flex;
      }

      .categories-content__container {
        top: 34px;
        box-shadow: 0px 20px 20px 0px #0000001A;
      }
    }
  }

  .menu-content__container_active {
    display: block;

    .nav-link {
      font-weight: 500;
    }
  }
}

.nav-link {
  color: #333333;
  padding: 13px 0;
  text-decoration: none;
  display: block;

  &:hover {
    color: #F74623;
  }
}

.burger-icon__container {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100px;
  justify-content: center;
  height: 80px;

  @media (max-width: 375px) {
    width: 80px;
  }

  @media (min-width: 1700px) {
    display: none;
  }
}

.burger-icon {
  background-image: url("./img/burger.svg");
  width: 20px;
  height: 14px;
}

.header-arrow {
  position: absolute;
  background-image: url("./img/header-arrow.svg");
  width: 27px;
  height: 27px;
  top: 0;
  left: 0;
}

@mixin logo($url) {
  // background-image: url($url);
  // background-size: 80%;
  // background-repeat: no-repeat;
  // background-position: center center;
  // width: 150px;
  // height: 27px;
  display: flex;
  align-items: center;

  img {
    width: 150px;
    height: 31px;

    // @media (min-width: 1700px) {

    // }
  }
}

.header-logo {
  @include logo("./img/logo_color_horizontal.svg");
}

.header-categories__container {
  padding: 29px 53px 29px 40px;
  position: relative;
  @include header-item;

  &>div:last-of-type {
    font-size: 18px;
    font-weight: 500;
  }

  &:hover {
    color: #F74623;

    .header-categories-icon {
      background-image: url("./img/categories-menu-icon-hover.svg");
    } 
  }

  .categories-content__container {
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 160px;
    box-shadow: 0px 20px 50px 0px #0000001A;
    z-index: 5;
    top: 81px;
    left: 0px;
    width: 300px;
    padding: 24px 0;

    .nav-link {
      font-weight: 500;
    }

    @for $i from 1 through 8 {
      .categories-menu__item_#{$i} {
        display: flex;
        justify-content: space-between;
        padding-left: 30px;
        padding-right: 30px;
        border-left: 1px solid #ffffff;

        .arrow-right {
          width: 12px;
          height: 20px;
          background-image: url("./img/arrow-right.svg");
          background-repeat: no-repeat;
          background-position: center center;
        }

        &:hover {
          border-color: #F74623;

          .arrow-right {
            background-image: url("./img/arrow-right-active.svg");
          }
        }

        .categories-content-item__container {
          display: none;
          position: absolute;
          background-color: #ffffff;
          min-width: 160px;
          box-shadow: 50px 20px 50px 0px #0000000D;
          z-index: 5;
          top: 0px;
          left: 300px;
          width: 300px;
          padding: 22px 0 22px 30px;
          border-left: $border;

          @media (max-width: 600px) {
            top: 160px;
            left: 15px;
          }

          .nav-link {
            font-size: 14px;
          }
        }

        &:hover .categories-content-item__container {
          display: block;
        }
      }
    }
  }

  &:hover .categories-content__container {
    display: block;
  }

  @media (max-width: 1000px) {
    display: none;
  }

  @media (max-width: 1171px) {
    border-right: none;
  }
}

.header-categories-icon {
  width: 14px;
  height: 12px;
  background-image: url("./img/categories-menu-icon.svg");
  margin-right: 16px;
}

.header-nav__container {
  @include header-item;
  justify-content: space-around;
}

.header-link__container {
  @include header-item;
  padding: 29px 40px;

  @media (max-width: 1300px) {
    border-right: none;
  }

  @media (max-width: 1171px) {
    display: none;
  }

  &.nav-links {
    display: none;
  }

  .link {
    font-weight: 500;
  }

  @media (min-width: 1700px) {
    &.nav-links {
      display: flex;
    }
    flex-shrink: 0;
    .nav-link {
      margin-right: 40px;
      font-size: 18px;
      line-height: 21px;
      font-weight: 500;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

.link {
  text-decoration: none;
  font-size: 18px;
  line-height: 21px;
  color: #F74623
}

.magnifier-icon__container {
  padding: 30px;
  cursor: pointer;
  border-left: $border;
  border-right: $border;
  @media (max-width: 640px) {
    display: none;
  }
}

.magnifier-icon {
  width: 20px;
  height: 20px;
  background-image: url("./img/magnifier-header.svg");
}

.bell-icon__container {
  position: relative;
  padding: 28px 29px;
  cursor: pointer;
  border-right: $border;
  @media (max-width: 640px) {
    display: none;
  }
}

.notification-indicator {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #F74623;
  border-radius: 50%;
  top: 29px;
  left: 42px;
}

.bell-icon {
  width: 21px;
  height: 24px;
  background-image: url("./img/bell-icon.svg");
}

.cart-icon__container {
  width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;

  > span {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 5px;
    color: #F74623;
  }

  @media (max-width: 640px) {
    border-left: $border;
  }
}

.cart-icon {
  width: 24px;
  height: 24px;
  // background-image: url("./img/cart-icon.svg");
  background-image: url("./img/cart-icon-active.svg");
}

.authorization-online {
  cursor: pointer;
  position: relative;

  .account-menu-content__container {
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 160px;
    box-shadow: 0px 20px 50px 0px #0000001A;
    z-index: 5;
    top: 81px;
    right: 0px;
    width: 200px;
    padding: 8px 0;

    .nav-link {
      display: flex;
      align-items: center;
      width: 100%;
      padding-left: 23px;

      > div {
        margin-right: 19px;
      }
    }

    .menu-dashboard-icon {
      width: 18px;
      height: 18px;
      background-image: url("./img/dasboard-icon.svg");
    }

    .menu-message-icon {
      width: 18px;
      height: 18px;
      background-image: url("./img/message-icon.svg");
    }

    .menu-quotes-icon {
      width: 16px;
      height: 20px;
      background-image: url("./img/quotes-icon.svg");
    }

    .menu-orders-icon {
      width: 18px;
      height: 20px;
      background-image: url("./img/orders-icon.svg");
    }

    .menu-payments-icon {
      width: 20px;
      height: 16px;
      background-image: url("./img/payments-icon.svg");
    }

    .menu-profile-icon {
      width: 18px;
      height: 18px;
      background-image: url("./img/profile-icon.svg");
    }

    .menu-logout-icon {
      width: 18px;
      height: 18px;
      background-image: url("./img/logout-icon.svg");
    }
  }

  &:hover {
    color: #F74623;

    .account-menu-content__container {
      display: block;
    }
  }
}

.authorization__container {
  padding: 28px 40px;
  border-right: $border;

  > div {
    display: flex;

    .user-icon {
      width: 24px;
      height: 24px;
      background-image: url("./img/user-icon.svg");
    }

    .account-name__container {
      display: flex;
      align-items: center;
      font-weight: 500;

      > span {
        margin: 0 10px;
      }

      .arrow-down {
        width: 11px;
        height: 7px;
        background-image: url("./img/arrow-down.svg");
      }

      @media (max-width: 780px) {
        display: none;
      }
    }
  }

  @media (max-width: 640px) {
    display: none;
  }
}


main {
  max-width: 1920px;
  flex: 1 0 auto;
  margin-top: 80px;
  background-color: #FBFBFD;
  @media(min-width: 1920px) {
    margin: 80px auto 0 auto;
    min-width: 1903px;
    max-width: 1920px;
  }

  &.main-page {
    @media(min-width: 1920px) {
      margin: 80px auto 0 auto;
      min-width: 1903px;
      max-width: 1903px;
    }
  }
}

.modal__container_search {
  background-color: #ffffff;
  width: 100%;
  position: absolute;
  top: 80px;
}

footer {
  flex: 0 0 auto;
  background-color: #221B33;
  display: flex;
  flex-direction: column;
}

$footer-border: 1px solid #E7E8EA33;

.footer-social__container {
  padding: 15px 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: $footer-border;

  @media (min-width: 1700px) {
    padding: 15px 260px;
  }

  @media (max-width: 1280px) {
    padding: 59px 60px 40px;
  }

  @media (max-width: 1150px) {
    padding: 59px 50px 40px;
    flex-direction: column;
    align-items: flex-start;
    .social-links__container {
      margin-left: 14px;
      margin-top: 50px;
    }
  }

  @media (max-width: 500px) {
    padding: 59px 20px 26px;
  }

  .link {
    color: #ffffff;
  }

  .social-links__container {
    display: flex;

    @media (max-width: 665px) {
      flex-wrap: wrap;
      align-items: center;
    }
  }

  .social-links {
    display: flex;
    align-items: center;

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        margin-right: 40px;
      }
    }

    & > div:last-child {
      margin-right: 50px;

      a:last-child {
        margin-right: 0;
      }
    }

    @media (max-width: 655px) {
      margin-bottom: 20px;
    }

    @media (max-width: 450px) {
      flex-direction: column;
      align-items: flex-start;
      & > div:last-child {
        margin-top: 20px;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .youtube-icon {
    width: 20px;
    height: 20px;
    background-image: url("./img/youtube-icon.svg");
  }

  .linkedin-icon {
    width: 20px;
    height: 20px;
    background-image: url("./img/linkedin-icon.svg");
  }
}

.footer-logo {
  @include logo("./img/footer-logo.svg");
}

.footer-links__container {
  padding: 59px 100px 40px;
  display: flex;
  justify-content: space-between;

  @media (min-width: 1700px) {
    padding: 59px 260px;
  }

  @media (max-width: 1280px) {
    padding: 59px 60px 26px;
  }

  @media (max-width: 1150px) {
    padding: 59px 50px 26px;
    flex-direction: column;
  }

  @media (max-width: 500px) {
    padding: 59px 20px 26px;
  }

  .links__wrapper {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;

    & > div:first-child {
      display: flex;
      width: 100%;

      .links__container:first-child {
        width: 65%;

        @media (max-width: 750px) {
          width: 50%;
          margin-right: 20px;
        }

        @media (max-width: 450px) {
          width: 100%;
        }
      }

      .links__container:last-child {
        @media (max-width: 750px) {
          margin-top: 0;
          width: 50%;
        }
      }

      .links__container:last-child {
        @media (max-width: 450px) {
          margin-top: 0;
          width: 100%;
        }
      }

      @media (max-width: 450px) {
        flex-direction: column;
        .links__container:last-child {
          margin-top: 40px;
        }
      }
    }

    @media (max-width: 750px) {
      flex-direction: column;
    }
  }

  .links__container:last-child {
    width: 60%;

    @media (max-width: 750px) {
      margin-top: 40px;
      width: 200px;
    }

    @media (max-width: 450px) {
      width: 100%;
    }
  }

  .link {
    color: #ffffff;
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 8px;
    width: fit-content;
    margin-right: 10px;

    &:hover {
      color: #F74623;
      text-decoration: underline;
    }
  }

  .links-title {
    color: #F74623;
    padding-bottom: 13px;
    border-bottom: $footer-border;
  }

  .links__list {
    padding-top: 27px;
    display: flex;
    flex-direction: column;
  }

  .subscribe__wrapper {
    p {
      padding-top: 27px;
      font-weight: 400;
      font-size: 14px;
      width: 315px;
      color: #ffffff;
      margin-bottom: 20px;
    }

    .subscribe__container {
      display: flex;

      input {
        width: 318px;
        height: 50px;
        border-radius: 0;
        border: $footer-border;
        font-size: 16px;
        padding-left: 16px;

        @media (max-width: 400px) {
          width: 100%;
        }
      }

      button {
        background-color: transparent;
        border-top: $footer-border;
        border-right: $footer-border;
        border-bottom: $footer-border;
      }
    }

    @media (max-width: 1150px) {
      margin-top: 40px;
      width: 370px;

      p {
        width: 370px;
      }
    }

    @media (max-width: 400px) {
      width: 100%;

      p {
        width: 100%;
      }
    }
  }
}

.footer-contact__container {
  padding: 31px 100px;
  background-color: #251E38;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 1700px) {
    padding: 32px 260px;
  }

  @media (max-width: 1280px) {
    padding: 32px 60px;
  }

  @media (max-width: 1150px) {
    padding: 32px 50px;
  }

  @media (max-width: 500px) {
    padding: 40px 20px;
  }

  p {
    color: #ffffff;
    font-weight: 400;
    line-height: 18px;
  }

  @media (max-width: 928px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-bottom: 50px;
  }

  @media (max-width: 620px) {
    padding-bottom: 40px;
  }

  & > div:last-child {
    display: flex;

    > div {
      display: flex;

      span {
        color: #828282;
        margin-right: 5px;
      }

      a {
        color: #ffffff;
        text-decoration: none;
      }

      &:last-child {
        margin-left: 40px;
      }
    }

    @media (max-width: 928px) {
      margin-top: 40px;
    }

    @media (max-width: 700px) {
      justify-content: space-between;
      width: 100%;
      > div:last-child {
        margin-left: 0;
      }
    }

    @media (max-width: 620px) {
      flex-direction: column;
      > div:last-child {
        margin-top: 40px;
      }
    }
  }
}


/////////////////// main //////////////////////////////

.search__container {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;

  & > div {
    display: flex;
    align-items: center;

    &:first-child {
      flex-grow: 1;
      justify-content: center;
    }
  }

  .search-icon {
    width: 20px;
    height: 20px;
    background-image: url("./img/search-icon.svg");
    margin-right: 18px;
  }

  input {
    width: 280px;
    height: 20px;
    border: none;
    font-size: 16px;
    background-color: transparent;

    @media (max-width: 370px) {
      width: 200px;
    }
  }
}

.cancel-icon {
  width: 20px;
  height: 20px;
  background-image: url("./img/cancel-icon.svg");
  cursor: pointer;
}

.top-marketing__wrapper {
  display: flex;

  @media (max-width: 1100px) {
    flex-direction: column;
  }

  .main-marketplace__container {
    background-color: #F75E23;
    width: 73%;
    display: flex;
    align-items: center;
    padding-right: 40px;

    @media (max-width: 1100px) {
      width: auto;
    }

    @media (min-width: 1500px) {
      height: 600px;
    }

    .main-marketplace-image {
      background-image: url("./img/marketplace-left.png");
      width: 354px;
      height: 500px;
      background-repeat: no-repeat;
      background-position: center center;
      background-position-x: -75px;
      background-size: 340px;

      @media (max-width: 1280px) {
        width: 240px;
        background-position-x: -136px;
      }

      @media (max-width: 1100px) {
        width: 411px;
        background-position-x: -150px;
      }

      @media (max-width: 855px) {
        width: 432px;
        background-position-x: -186px;
      }

      @media (max-width: 670px) {
        display: none;
      }

      @media (min-width: 1500px) {
        background-position-x: 0;
        width: 428px;
      }

    }

    .main-marketplace__content {
      display: flex;
      flex-direction: column;

      @media (max-width: 1100px) {
        padding-right: 50px;
      }

      @media (max-width: 670px) {
        padding: 60px 50px 60px 60px;
      }

      @media (max-width: 425px) {
        padding: 60px 20px 60px;
      }

      @media (max-width: 330px) {
        padding: 60px 10px 60px;
      }

      h1 {
        // width: 600px;
        color: #ffffff;
      }

      p {
        width: 600px;
        font-weight: 300;
        color: #ffffff;
        font-size: 30px;
        line-height: 39px;
        margin: 40px 0;
      }

      @media (max-width: 1100px) {
        h1, p {
          width: 100%;
        }
      }

      a {
        color: #ffffff;
        text-decoration: none;
        display: flex;
        align-items: center;

        span {
          font-size: 18px;
          margin-right: 10px;
        }
      }
    }
  }

  .main-request__container {
    width: 23%;
    padding: 138px 20px 138px 39px;
    background-image: url("./img/bg-request.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 1000px;

    @media (max-width: 1150px) {
      width: auto;
      padding: 80px 20px 80px 39px;
      background-size: 100%;
    }

    @media (max-width: 500px) {
      width: auto;
      padding: 80px 20px 80px 39px;
      background-size: 140% 100%;
    }

    h3 {
      width: 98%;
      color: #F74623;
      line-height: 39px;
    }

    > div {
      width: 98%;
      margin: 40px 0;

      p {
        font-size: 16px;
        font-weight: 400;

        &:last-child {
          margin-top: 20px;
        }
      }
    }

    a {
      color: #333333;
      text-decoration: none;
      display: flex;
      align-items: center;

      span {
        font-size: 18px;
        margin-right: 10px;
        font-weight: 500;
      }
    }
  }
}

.arrow-right-white {
  background-image: url("./img/arrow-right-white.svg");
  width: 20px;
  height: 20px
}

.arrow-right-red {
  background-image: url("./img/arrow-right-red.svg");
  width: 20px;
  height: 20px
}

.main-categories__wrapper {
  .main-categories__title {
    padding: 80px 50px 60px;
    color: #333333;
    text-align: center;
    white-space: pre-wrap;

    @media (max-width: 640px) {
      padding: 80px 100px 60px;
    }

    @media (max-width: 3750px) {
      padding: 80px 20px 60px;
    }

    a {
      color: #333333;
      text-decoration: none;
      &:hover {
        color: #F74623;
        text-decoration: underline;
      } 
    }
  }

  .main-categories__container {
    display: flex;
    justify-content: center;
    flex-direction: column;

    & > div {
      display: flex;
      @media (max-width: 640px) {
        flex-direction: column;
      }

      &:last-child {
        @media (max-width: 640px) {
          display: none;
        }
      }
    }

    .main-category__container {
      padding: 38px 47px 38px 40px;
      flex-grow: 1;
      border-top: $border;

      &:hover {
        box-shadow: 0px 20px 50px 0px #0000001A;
      }

      @media (max-width: 640px) {
        padding: 38px 100px;
      }

      @media (max-width: 375px) {
        padding: 38px 20px;
      }

      &:nth-child(2n) {
        border-left: $border;
        border-right: $border;
      }

      a {
        text-decoration: none;
        width: fit-content;
        max-width: 360px;
      }

      h4 {
        color: #F74623;
        max-width: 360px;
      }

      p {
        color: #828282;
        margin-top: 16px;
        max-width: 360px;
        line-height: 19px;

        @media (max-width: 800px) {
          display: none;
        }
      }

      ul {
        padding-left: 24px;
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        max-width: 360px;

        li {
          line-height: 19px;
          color: #333333;
          width: fit-content;
          a {
            color: #333333;;
          }
          &:hover {
            color: #F74623;
            text-decoration: underline;
            a {
              color: #F74623;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

.bottom-link {
  display: flex;
  text-decoration: none;
  padding: 31px 10px;
  align-items: center;
  justify-content: center;
  border-top: $border;
  border-bottom: $border;
  transition: .2s;
  font-weight: 500;

  &.bottom-link_borders-none {
    border: none;
  }

  &:hover {
    background-color: #F74623;

    span {
      margin-right: 10px;
      color: #ffffff;
      font-weight: 500;
    }

    .arrow-link-right {
      background-image: url("./img/arrow-right-white.svg");
    }
  }

  span {
    margin-right: 10px;
    color: #333333;
  }

  .arrow-link-right {
    background-image: url("./img/arrow-right-red.svg");
    width: 20px;
    height: 20px
  }
}

.main-brands__wrapper {
  padding: 80px 0;

  .main-brands__container {
    display: flex;
    border-top: $border;
    border-bottom: $border;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none;
    margin: auto;

    &::-webkit-scrollbar {
      height: 0;
    }

    @media (max-width: 640px) {
      flex-direction: column;
      overflow-x: unset;
    }

    & > div {
      display: flex;

      &:first-child {
        border-right: $border;
      }

      @media (max-width: 640px) {
        &:first-child {
          border-right: none;
        }
      }

      @media (max-width: 500px) {
        flex-direction: column;
      }

      .main-brand__wrapper {
        border-right: $border;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 90%;
        transition: .1s;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 474px;
        height: 270px;

        &:hover {
          img {
            width: 474px;
            height: 270px;
          }
        }

        img {
          width: 427px;
          height: 222px;
        }

        @media (max-width: 1500px) {
          width: 355px;
          height: 202px;

          &:hover {
            img {
              width: 360px;
              height: 202px;
            }
          }

          img {
            width: 324px;
            height: 166px;
          }
        }

        @media (max-width: 640px) {
          width: 320px;
          height: 180px;

          &:hover {
            img {
              width: 100%;
              height: 180px;
            }
          }

          img {
            width: 90%;
            height: 148px;
          }
        }

        @media (max-width: 500px) {
          width: 320px;
          height: 180px;

          &:hover {
            img {
              width: 320px;
              height: 180px;
            }
          }

          img {
            width: 288px;
            height: 148px;
          }
        }

        @media (max-width: 640px) {
          width: 50%;
          border-bottom: $border;
        }

        @media (max-width: 500px) {
          width: 100%;
          display: flex;
          justify-content: center;
          border-right: none;
          border-bottom: $border;
        }

        &:last-child {
          border-right: none;
        }

        .main-brand__container {
          margin: 17px 17px;
          width: 90%;
          height: 166px;
          display: flex;
          align-items: center;
          transition: .3s;

          @media (min-width: 1440px) {
            height: 222px;
          }

          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;
          transition: .1s;

          @media (max-width: 640px) {
            margin: 16px;
          }

          @media (max-width: 500px) {
            width: 65%;
          }

          @media (max-width: 400px) {
            width: 75%;
          }
        }

        .main-brand__content {
          padding: 31px 25px;
          width: 275px;

          @media (min-width: 1440px) {
            width: 100%;
          }

          @media (max-width: 640px) {
            width: 244px;
            padding: 29px 22px;
          }

          @media (max-width: 500px) {
            width: 100%;
          }
        }

        &:hover {
          background-size: 102%;
        }

        @media (max-width: 640px) {
          background-size: 90% 75%;

          &:hover {
            background-size: cover;
          }
        }

        @media (max-width: 500px) {
          background-size: 75% 85%;

          &:hover {
            background-size: 85%;
          }
        }

        @media (max-width: 400px) {
          background-size: 75% 85%;

          &:hover {
            background-size: cover;
          }
        }

        @media (max-width: 390px) {
          background-size: 75% 75%;
        }

        &:nth-child(2n - 1) {

          @media (max-width: 640px) {
            background-image: none;
          }

          &:hover {

            .main-brand__container {
              background-image: none;
            }
          }

          .main-brand__container {

            @media (max-width: 640px) {
              background-image: url("./img/eagle-brand.jpg");
            }

            h4 {
              font-size: 18px;
              color: #ffffff;
              line-height: 23px;
              margin: 13px 0;
            }

            p {
              width: 134px;
              font-weight: 500;
              font-size: 10.5px;
              line-height: 14px;
              color: #ffffff;
            }

            .brand-logo {
              width: 78px;
              height: 23px;
              background-repeat: no-repeat;
              background-position: center center;
              background-image: url("./img/wzl-logo.png");
            }
          }
        }

        &:nth-child(2n) {
          @media (max-width: 640px) {
            background-image: none;
          }

          &:hover {

            .main-brand__container {
              background-image: none;
            }
          }

          .main-brand__container {
            @media (max-width: 640px) {
              background-image: url("./img/map-brand.jpg");
            }

            p {
              font-weight: 500;
              font-size: 18px;
              line-height: 23px;
              width: 165px;
              margin: auto;

              @media (max-width: 640px) {
                font-size: 16px;
                line-height: 20px;
              }
            }

            .brand-logo {
              width: 120px;
              height: 43px;
              margin-bottom: 24px;
              background-repeat: no-repeat;
              background-position: center center;
              background-image: url("./img/nt-logo.png");
            }
          }
        }
      }
    }
  }
}

.main-products__wrapper {
  .products-lists__container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px 20px 50px;

    @media (max-width: 600px) {
      overflow-x: auto;
      scrollbar-width: none;
      white-space: nowrap;
      justify-content: flex-start;
      &::-webkit-scrollbar {
        height: 10px;
      }
    }

    h2 {
      cursor: pointer;
      border-bottom: 1px solid transparent;

      &:nth-child(2) {
        margin: 0 40px;
      }

      &.active {
        border-bottom: 1px solid #F74623;
      }
      padding-bottom: 10px;
    }
  }

  .products__container {
    display: flex;
    border-top: $border;

    // @media (max-width: 1440px) {
    overflow-x: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      height: 0;
    }

    // }
    .product__container {
      padding: 55px 60px;
      transition: .3s;
      width: 30%;
      border-right: $border;
      flex-shrink: 0;

      @media (min-width: 1440px) {
        width: 18.7%;
      }

      @media (max-width: 1024px) {
        padding: 46px 40px;
      }

      @media (max-width: 800px) {
        width: 35%;
        flex-shrink: 0;
      }

      @media (max-width: 500px) {
        width: 50%;
      }

      @media (max-width: 400px) {
        width: 60%;
      }

      &:hover {
        background-color: #ffffff;
        box-shadow: 0px 20px 50px 0px #0000001A;

        // a {
        //   color: #F74623;
        //   text-decoration: underline;
        // }
      }

      // &:nth-child(2) {
      //   border-right: $border;
      //   border-left: $border;
      // }

      &:last-child {
        border-right: none;
      }

      a {
        transition: .3s;
        text-decoration: none;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        color: #333333;

        &:hover {
          color: #F74623;
        }
      }

      .product__picture {
        // width: 175px;
        // height: 175px;
        margin: 24px 0;
        // background-image: url("./img/product-pic.png");

        img {
          width: 175px;
          height: 175px;
        }
      }

      ul {
        margin-left: 25px;
        margin-bottom: 24px;
        line-height: 19px;
        @media (max-width: 800px) {
          display: none;
        }

        a:hover {
          text-decoration: underline;
          color: #333333;
        }
      }

      p {
        color: #000000;
        font-weight: 300;

        a {
          font-weight: 500;
          &:hover {
            color: #000000;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.main-products-info__wrapper {
  & > div:nth-child(2) {
    display: block;
    background-color: #ffffff;
  }

  h2 {
    font-size: 36px;
    line-height: 46px;
    color: #F74623;
  }

  button {
    border: 1px solid #F2F2F2;
    background-color: transparent;

    &:hover {
      border: 1px solid #F2F2F2;
      background-color: transparent;
      color: #F74623;
    }
  }

  & > div {
    position: relative;
    padding: 140px 100px;
    @media (max-width: 1280px) {
      padding: 100px 60px;
    }

    @media (max-width: 1100px) {
      padding: 80px 52px;
    }

    @media (max-width: 540px) {
      padding: 80px 20px;
    }
    display: flex;
    justify-content: space-between;

    @media (min-width: 1700px) {
      justify-content: flex-start;
      padding: 140px 260px;

      >div:first-child {
        margin-right: 160px;
      }
    }
    @media (max-width: 1100px) {
      &:first-child {
        flex-direction: column;
      }
    }

    .info__container {
      h2 {
        width: 560px;

        @media (max-width: 1100px) {
          width: 100%;
        }
      }

      p {
        width: 560px;
        margin: 40px 0;

        @media (max-width: 640px) {
          width: 100%;
        }
      }

      .button-message {
        &:hover {
          border-color: #BDBDBD;
        }
      }
    }

    .elements_top {
      background-image: url("./img/main-bg-elem-top.png");
      width: 452px;
      height: 339px;
      z-index: 0;
      position: absolute;
      right: 0;
      bottom: 0;
      @media (max-width: 1280px) {
        display: none;
      }
    }

    .elements_bot {
      background-image: url("./img/main-bg-elem-bot.png");
      width: 678px;
      height: 565px;
      z-index: 0;
      position: absolute;
      right: 0;
      top: 0;
      margin-right: 0 !important;
      @media (max-width: 1280px) {
        display: none;
      }
    }

    .statistics__container {
      display: flex;
      width: 560px;
      justify-content: space-between;
      z-index: 1;

      @media (max-width: 1100px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 60px;
      }

      @media (max-width: 890px) {
        width: 100%;
        justify-content: flex-start;
        flex-direction: column;
      }

      @media (max-width: 540px) {
        width: 188px;
      }

      .statistics__column {
        @media (max-width: 1100px) {
          display: flex;
          align-items: center;
          justify-content: space-around;
          width: 50%;
        }

        @media (max-width: 890px) {
          justify-content: flex-start;
          width: 100%;
          & > div:last-child {
            margin-right: 0;
          }
          & > div:first-child {
            margin-right: 52px;
          }
        }


        @media (max-width: 540px) {
          flex-direction: column;
          & > div:first-child {
            margin-right: 0;
            margin-bottom: 20px;
          }
        }

        & > div {
          display: flex;
          flex-direction: column;
          width: 188px;
          border-bottom: 1px solid #828282;
          padding-bottom: 19px;
          margin-right: 15px;

          @media (max-width: 1100px) {
            display: flex;
            justify-content: space-between;
          }

          &:last-child {
            margin-top: 20px;

            @media (max-width: 1100px) {
              margin-top: 0;
            }
          }

          span {
            &:first-child {
              font-weight: 200;
              font-size: 64px;
              line-height: 83px;
              color: #333333;
            }

            &:last-child {
              color: #828282;
              font-size: 18px;
              line-height: 24px;
              font-weight: normal;
            }
          }
        }
      }
    }
  }

  .benefits__wrapper {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 960px) {
      flex-direction: column;
    }

    h3 {
      font-weight: normal;
      font-size: 30px;
      line-height: 39px;
    }

    p {
      margin-top: 30px;
      padding-bottom: 40px;
      border-bottom: $border;
      margin-bottom: 40px;
      font-size: 18px;
      line-height: 24px;
      color: #828282;
    }

    ul {
      margin-left: 25px;
      width: 480px;
      font-size: 18px;
      line-height: 24px;
      color: #333333;

      @media (min-width: 1500px) {
        width: 541px;
      }

      @media (max-width: 1090px) {
        width: 420px;
      }

      li {
        margin-bottom: 25px;
      }
    }

    .benefits__container {
      width: 48%;

      a {
        margin-left: 25px;
      }

      .button-message {
        &:hover {
          border-color: #BDBDBD;
        }
      }

      @media (max-width: 960px) {
        width: 100%;
        &:last-child {
          margin-top: 40px;
        }
        ul {
          width: 90%;
        }
      }
    }
  }

  .security-info__container {
    background-image: url("./img/main-purple-bg.jpg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center top;
    display: flex;
    padding: 80px 95px 80px 100px;
    justify-content: space-between;
    align-items: center;
    @media (min-width: 1700px) {
      padding: 80px 95px 80px 260px;
      justify-content: flex-start;
    }

    @media (max-width: 1280px) {
      padding: 80px 60px 56px;
    }

    @media (max-width: 1024px) {
      padding: 80px 52px 56px;
    }

    @media (max-width: 850px) {
      flex-direction: column;
      align-items: flex-start;
      background-size: 1125px 700px;
      background-position: center bottom;
    }

    @media (max-width: 540px) {
      padding: 40px 20px;
    }

    @media (max-width: 340px) {
      background-size: 1125px 800px;
    }

    & > div, & > ul {
      width: 50%;
      max-width: 560px;

      @media (max-width: 850px) {
        width: 80%;
      }

      @media (max-width: 640px) {
        width: 95%;
      }
    }

    p {
      margin: 40px 0;
      font-size: 18px;
      line-height: 24px;
      color: #FFFFFF;
    }

    ul {
      font-size: 18px;
      line-height: 24px;
      color: #FFFFFF;
      margin-left: 30px;

      @media (max-width: 850px) {
        margin-left: 20px;
      }

      li {
        margin: 24px 0;

        &::marker {
          color: #635BFF;
          font-size: 18px;
        }
      }
    }
  }
}

.stripe-logo {
  width: 150px;
  height: 34px;
  background-image: url("./img/stripe-logo.png");
}

.all-brands__wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  @media (max-width: 780px) {
    flex-direction: column;
  }

  .brands__info {
    padding: 100px 151px 71px 100px;
    @media (min-width: 1700px) {
      padding: 130px 211px 137px 260px;
    }
    border-bottom: $border;

    h3 {
      width: 600px;

      @media (min-width: 1700px) {
        width: 560px;
      }

      span {
        color: #F74623;
      }

      @media (max-width: 331px) {
        font-size: 28px;
      }
    }

    flex-grow: 3;
    @media (max-width: 1437px) {
      padding: 102px 259px 102px 100px;
    }
    @media (max-width: 1279px) {
      padding: 80px 372px 80px 52px;
      flex-grow: 1;
    }
    @media (max-width: 1022px) {
      width: 100%;
      padding: 80px 60px;
      h3 {
        width: 100%;
      }
    }
    @media (max-width: 780px) {
      width: auto;
    }
    @media (max-width: 540px) {
      padding: 80px 20px 40px;
    }
  }

  .brand__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 283px;
    height: 287px;
    @media (min-width: 1700px) {
      width: 343px;
      height: 383px;
    }
    text-decoration: none;
    justify-content: center;
    border-bottom: $border;
    border-left: $border;
    transition: .3s;
    flex-grow: 1;

    &:nth-child(4), &:nth-child(9) {
      border-left: none;
    }

    @media (max-width: 1437px) {
      &:nth-child(4), &:nth-child(9) {
        border-left: $border;
      }
      width: 319px;
      height: 319px;
      &:nth-child(7), &:nth-child(3) {
        border-left: none;
      }
      &:nth-child(11), &:nth-child(12), &:nth-child(13) {
        display: none;
      }
    }

    @media (max-width: 1279px) {
      width: 340px;
      height: 340px;
      &:nth-child(3), &:nth-child(7) {
        border-left: $border;
      }
      &:nth-child(2), &:nth-child(5) {
        border-left: none;
      }
      &:nth-child(8), &:nth-child(9), &:nth-child(10) {
        display: none;
      }
      flex-grow: 1;
    }

    @media (max-width: 1022px) {
      width: 390px;
      height: 340px;
      &:nth-child(5) {
        border-left: $border;
      }
      &:nth-child(2), &:nth-child(4) {
        border-left: none;
      }
      &:nth-child(6), &:nth-child(7) {
        display: none;
      }
    }

    @media (max-width: 780px) {
      height: 272px;
      width: 100%;
      &:nth-child(3) {
        border-left: none;
      }
      &:nth-child(4), &:nth-child(5) {
        display: none;
      }
    }

    &:hover {
      background-color: #ffffff;

      .brand-logo {
        filter: grayscale(0);
      }

      box-shadow: 0px 20px 50px 0px #0000001A;

      p {
        color: #F74623;
      }
    }

    .brand-logo {
      width: 225px;
      height: 128px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-size: 65%;
      background-repeat: no-repeat;
      background-position: center center;
      transition: .3s;
      filter: grayscale(100%);

      img {
        width: 135px;
        height: 115px;
      }
    }

    p {
      width: 180px;
      text-align: center;
      font-weight: 500;
      font-size: 13.493px;
      line-height: 16px;
      color: #333333;
      transition: .3s;

      @media (min-width: 1700px) {
        width: 240px;
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
}

.blog__wrapper {
  border-top: $border;
  h2 {
    text-align: center;
    padding-top: 80px;
    padding-bottom: 60px;
    color: #333333;
  }
  .blog__container {
    border-top: $border;
    border-bottom: $border;
    display: flex;
    overflow-x: auto;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      height: 0;
    }

    .blog-item__container {
      transition: .3s;
      width: 480px;
      text-decoration: none;
      color: #333333;
      flex-shrink: 0;

      @media (max-width: 1280px) {
        width: 427px;
      }

      @media (max-width: 1024px) {
        width: 341px;
      }

      @media (max-width: 640px) {
        width: 320px;
      }

      @media (max-width: 325px) {
        width: 300px;
      }

      &:hover {
        background-color: #ffffff;
        box-shadow: 0px 20px 50px 0px #0000001A;

        p {
          color: #F74623;
        }
      }

      &:last-child {
        border-right: none;
      }

      .content__container {
        border-right: $border;
        padding: 24px 24px 44px;

        @media (max-width: 1280px) {
          padding: 24px 20px 44px;
        }

        @media (max-width: 1024px) {
          padding: 24px 12px 44px;
        }

        @media (max-width: 640px) {
          padding: 24px 20px 44px;
        }
      }

      img {
        @media (max-width: 1280px) {
          width: 427px;
          height: 284px;
        }
        @media (max-width: 1024px) {
          width: 341px;
          height: 227px;
        }
        @media (max-width: 640px) {
          width: 320px;
          height: 212px;
        }

        @media (max-width: 325px) {
          width: 300px;
          height: 200px;
        }
      }

      p {
        font-size: 18px;
        line-height: 20px;
        font-weight: 500;
        margin-bottom: 24px;
        width: 360px;

        @media (max-width: 1024px) {
          width: 100%;
        }
      }

      .tags__container {
        display: flex;
        font-size: 16px;
        line-height: 19px;

        .tag__container {
          margin-right: 20px;
        }

        span {
          color: #F74623;
          margin-right: 10px;
        }
      }
    }
  }
}