.brand__wrapper{
  padding: 60px 0px 60px 40px;
  display: flex;
  .brand__logo{
    background-size: 100% auto;
    min-width: 240px;
    max-height: 240px;
  }
  .brand__description{
    padding-top: 50px;
    margin-left: 90px;
    .description__title{
      margin-bottom: 25px;
    }
    p{
      max-width: 1100px;
    }
    div{
      display: flex;
      margin-top:25px;
      p:first-child{
        color:#828282;
        margin-right: 10px;
      }
    }
  }
  @media(max-width: 1100px){
    display: block;
    .brand__description{
      margin:0;
    }
  }
}
.product-amount.brand{
  color: #828282;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}