.search-content__container {
  padding: 40px 40px 40px 20px;
  display: flex;
  justify-content: space-between;
  border-top: $border;

  @media (min-width: 1920px) {
    width: 1870px;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  @media (max-width: 600px) {
    padding-right: 20px;
  }

  .matching-element {
    color: #F74623 !important;
  }

  .elements-quantity {
    font-size: 16px;
    line-height: 19px;
    color: #828282;
    margin-bottom: 40px;
  }

  .matching-products__container {
    width: 59%;

    @media (min-width: 1700px) {
      width: 65%;
    }

    @media (max-width: 1024px) {
      display: contents;
    }

    .matching-products__row {
      display: flex;
      justify-content: space-between;

      @media (max-width: 600px) {
        flex-direction: column;
      }
    }

    .matching-product__container {
      margin-bottom: 60px;
      width: 260px;
      margin-right: 10px;

      @media (max-width: 1700px) {
        width: 240px;
        &:last-of-type {
          display: none;
        }
      }

      @media (max-width: 1280px) {
        width: 220px;
      }

      @media (max-width: 720px) {
        width: 180px;
      }

      @media (max-width: 600px) {
        width: 100%;
      }

      .matching-product__title {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #333333;
        text-decoration: none;
      }
  
      .product-image {
        // background-image: url("./img/product-pic.png");
        background-size: 80px 80px;
        background-repeat: no-repeat;
        background-position: center;
        margin: 10px 0;

        img {
          width: 80px;
          height: 80px;
        }
      }

      >p {
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 4px;
        white-space: pre-wrap;
  
        >span {
          margin-right: 5px;
          color: #828282;
        }
      }
    }
  }

  .matching-categories-brands__container {
    display: flex;
    width: 37%;
    justify-content: space-between;

    @media (min-width: 1700px) {
      width: 30%;
    }

    >div:first-child {
      margin-right: 10px;
    }

    @media (max-width: 1024px) {
      width: 100%;
      justify-content: flex-start;

      >div:first-child {
        margin-right: 20px;
      }
    }

    @media (max-width: 600px) {
      flex-direction: column;
    }

    .matching-categories__container, .matching-brands__container {
      width: 240px;
      display: flex;
      flex-direction: column;
      margin-top: 20px;

      @media (min-width: 1700px) {
        width: 260px;
      }

      @media (max-width: 1280px) {
        width: 220px;
      }

      @media (max-width: 600px) {
        width: 100%;
        justify-content: flex-start;
      }
    }

    .matching-products {
      font-size: 18px;
      line-height: 23px;
      color: #333333;
      text-decoration: none;
      font-weight: 500;
      margin-bottom: 20px;
      width: fit-content;

      .matching-element {
        font-weight: 500;
      }
  
      span {
        font-weight: 300;
        color: #828282;
      }
    }
  }
}