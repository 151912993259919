.checkout-page__title {
  padding: 60px 20px;
  color: #333333;

  @media (min-width: 1500px) {
    padding: 60px 200px;
  }

  @media (min-width: 1700px) {
    padding: 60px 260px;
  }
}

.checkout__wrapper {
  padding: 0 20px 50px;
  color: #333333;
  display: flex;
  justify-content: space-between;

  @media (min-width: 1700px) {
    padding: 0 260px 50px;
  }
  
  @media (max-width: 980px) {
    flex-direction: column-reverse;
  }

  .checkout__container {
    border: $border;
    width: 800px;

    @media (max-width: 1200px) {
      width: 650px;
    }

    @media (max-width: 980px) {
      width: 100%;
      margin-top: 30px;
    }

    .info__wrapper {
      padding: 30px 40px;
      @media (max-width: 630px) {
        padding: 30px 20px;
      }
      &.filled {
        .info__header span {
          display: block;
        }
      }
      &.active {
        background-color: #ffffff;
        .info__container {
          display: block;
        }

        .info__header {
          h2 {
            font-size: 36px;
            line-height: 42px;
          }
          span {
            display: none;
          }
        }
      }
      border-bottom: $border;
    }

    .info__header {
      display: flex;
      justify-content: space-between;
      height: 42px;
      align-items: center;

      h2 {
        font-weight: 500;
        font-size: 26px;
        line-height: 30px;
      }

      span {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #F74623;
        cursor: pointer;
        user-select: none;
        display: none;

        // &.visible {
        //   display: block;
        // }
      }

      // &.activeEdit span {
      //   display: block;
      // }

    }

    .info__container {
      display: none;
      margin-top: 40px;

      p {
        background-color: #FCEEE3;
        padding: 20px;
        margin-bottom: 30px;
      }

      .change-view {
        margin-bottom: 40px;

        @media (max-width: 700px) {
          flex-wrap: wrap;
        }

        @media (max-width: 630px) {
          > label {
            margin-bottom: 32px;
          }
        }
      }

      .invoice-address, .payment-type {
        display: block;

        &>div {
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          @media (max-width: 630px) {
            flex-direction: column;
            align-items: flex-start;

            &.two-items>div:first-child {
              margin-bottom: 20px;
            }
          }
  
          .form-item {
            input {
              &:first-child {
                margin-right: 30px;
              }
              &:disabled {
                margin-right: 0;
              }
            }
          }
  
          input[type="checkbox"] + label {
            font-size: 16px;
            line-height: 24px;
          }
  
          .question-icon{
            color: #F74623;
            border: $border;
            width: 18px;
            height: 18px;
            font-size: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            margin-left: 12px;
            cursor: default;
            padding: 0;
            .question-sign {
              width: 6px;
              height: 10px;
              background-image: url("./img/question-icon.svg");
            }

            .exclamation-sign {
              width: 2px;
              height: 10px;
              background-image: url("./img/exclamation-mark.svg");
            }
          }
        }
      }

      .with-tooltip {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .payment-type {
        p {
          width: 500px;
          background-color: #ffffff;
          font-size: 16px;
          line-height: 19px;
          margin: 0;
          padding: 0;

          @media (max-width: 630px) {
            width: 100%;
          }
        }

        &.by-card {
          >div:first-child {
            @media (max-width: 1200px) {
              flex-direction: column;
              align-items: flex-start;
              input {
                margin-bottom: 32px;
              }
            }
          }
        }

        &.ACH > .with-tooltip {
          display: flex;
          flex-direction: row !important;
          align-items: center;
        }
      }

      &>div {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        
        @media (max-width: 630px) {
          flex-direction: column;
          align-items: flex-start;

          &.two-items>div:first-child {
            margin-bottom: 20px;
          }

          &.with-tooltip {
            display: flex;
            flex-direction: row;
            align-items: center;
          }
        }

        .form-item {
          input {
            &:first-child {
              margin-right: 30px;
            }
            &:disabled {
              margin-right: 0;
            }
          }
        }

        input[type="checkbox"] + label {
          font-size: 16px;
          line-height: 24px;
        }

        >input[type="radio"] + label {
          font-size: 18px;
          line-height: 21px;
          margin-right: 40px;
        }
         

        .question-icon {
          position: relative;
          color: #F74623;
          border: $border;
          width: 18px;
          height: 18px;
          font-size: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          margin-left: 12px;
          padding: 0;
          cursor: default;

          .question-sign {
            width: 6px;
            height: 10px;
            background-image: url("./img/question-icon.svg");
          }

          .exclamation-sign {
            width: 2px;
            height: 10px;
            background-image: url("./img/exclamation-mark.svg");
          }

          &:hover .question-tooltip {
            display: block;
          }

          .question-tooltip {
            display: none;
            position: absolute;
            font-size: 13px;
            line-height: 15px;
            color: #777777;
            background-color: #ffffff;
            width: 200px;
            padding: 10px 20px;
            margin: 0;
            box-shadow: 0px 20px 50px 0px #0000001A;
            left: 32px;
          }
        }
      }

      .custom-select {

        .select-selected {
          border: $border;
          width: 270px;
          padding: 16px 14px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #828282;
          // color: #333333;

          @media (max-width: 1200px) {
            width: 240px;
          }

          @media (max-width: 700px) {
            width: 216px;
          }

          &.select-open {
            border-color: #828282;
          }

          &:hover {
            border-color: #BDBDBD;
          }
        }

        .select-icon {
          width: 11px;
          height: 7px;
          background-image: url("./img/select-arrow.svg");
        }
      }
    }
  }

  .orders__wrapper {
    width: 29%;

    @media (min-width: 1920px) {
      width: 33%;
    }
    
    .orders__header {
      display: none;
      height: 100px;
      justify-content: space-between;
      align-items: center;
      padding: 0 40px;
      cursor: pointer;

      h2 {
        font-size: 26px;
        line-height: 30px;
        color: #828282;
      }

      .select-icon {
        width: 11px;
        height: 7px;
        background-image: url("./img/select-arrow.svg");
        transition: .1s;
      }
    }

    @media (max-width: 980px) {
      width: 100%;
      background-color: #F2F2F2;

      .orders__container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 10px 20px 40px;
      }

      .orders__header {
        display: flex;
      }

      .orders__container, .order-price__container {
        display: none;
      }

      &.active {
        .orders__container, .order-price__container {
          display: block;
        }

        .orders__header .select-icon {
          transform: rotate(180deg);
        }
      }
    }
    .orders__container {
      padding-bottom: 40px;
      border-bottom: $border;

      .cart-item {
        padding: 0;
        justify-content: flex-end;
        @media (max-width: 980px) {
          max-width: 100%;
          justify-content: flex-start;
          flex-wrap: nowrap;
        }

        .cart-item__info {
          max-width: 410px;
          p {
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
          }
          @media (max-width: 980px) {
            max-width: 100%;
            width: 100%;
          }
          
        }

        .cart-item__img {
          width: 60px;
          height: 60px;
          background-repeat: no-repeat;
          background-size: 100%;
          background-position: center;
        }
      }
      .delivery-info {
        margin-top: 20px;
        font-size: 16px;
        line-height: 19px;
        white-space: pre-wrap;
        color: #333333;

        div:first-child {
          font-weight: 500;
        }
      }
    }

    .order-price__container {
      text-align: right;

      @media (max-width: 980px) {
        padding-bottom: 40px;
        padding-right: 20px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        margin-top: 20px;
        color: #828282;

        span {
          font-weight: 700;
          color: #333333;
        }
      }
    }
  }
}

.form-item {
  position: relative;
  width: fit-content;

  input:focus, textarea:focus {
    border-color: #828282;
  }

  input:hover, textarea:hover {
    border-color: #BDBDBD;
  }

  input:focus ~ .floating-label,
  input:not(:focus):valid ~ .floating-label,
  input:disabled ~ .floating-label,
  textarea:focus ~ .floating-label,
  textarea:not(:focus):valid ~ .floating-label,
  textarea:disabled ~ .floating-label {
    top: 8px;
    bottom: 10px;
    left: 16px;
    font-size: 11px;
    opacity: 1;
    line-height: 20px;
  }

  input {
    font-size: 16px;
    outline: none;
    width: 282px;
    background-color: transparent;
    border: none;
    line-height: 20px;
    padding: 23px 0 5px 16px;
    border: $border;
    height: 20px;
    &:disabled, &:disabled ~ .floating-label {
      opacity: 0.5;
    }

    &:invalid {
      // border-color: #EB5757;
    }

    @media (max-width: 1200px) {
      width: 252px;
    }

    @media (max-width: 700px) {
      width: 228px;
    }
  }

  textarea {
    font-size: 16px;
    outline: none;
    width: 630px;
    background-color: transparent;
    border: none;
    line-height: 20px;
    padding: 23px 0 5px 16px;
    border: $border;
    height: 96px;
    &:disabled, &:disabled ~ .floating-label {
      opacity: 0.5;
    }
    font-family: "Roboto" sans-serif;

    @media (max-width: 1200px) {
      width: 252px;
    }

    @media (max-width: 700px) {
      width: 228px;
    }
  }

  .floating-label {
    position: absolute;
    pointer-events: none;
    left: 16px;
    top: 16px;
    font-size: 16px;
    transition: 0.2s ease all;
    color: #828282;
  }

  &.full-line {
    width: 612px;
    input {
      width: 612px;
    }

    @media (max-width: 1200px) {
      width: 552px;
      input {
        width: 552px;
      }
    }

    @media (max-width: 700px) {
      width: 504px;
      input {
        width: 504px;
      }

      @media (max-width: 630px) {
        width: 228px;

        input {
          width: 228px;
        }
      }
    }
  }

  &.half-line {
    width: 132px;
    input {
      width: 132px;
    }
  }

  &.text {
    width: 100%;

    input {
      width: 100%;
      height: 80px;
      padding: 0;
      padding-left: 16px;
    }

    @media (max-width: 630px) {
      width: 90%;
      margin-right: 0;
    }
  }

  .error-message {
    margin-top: 6px;
    color: #EB5757;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
  }
}

.cards__container {
  display: flex;
  align-items: center;
  width: 392px;
  justify-content: space-between;

  @media (max-width: 630px) {
    width: 100%;
    flex-wrap: wrap;

    >div {
      margin-bottom: 30px;
      margin-right: 10px;
    }
  }

  >div>img {
    opacity: 0.5;
  }

  .american-express-icon {
    img {
      width: 101px;
      height: 30px;
    }
  }
  .cb-icon {
    img {
      width: 47px;
      height: 35px;
    }
  }
  .mastercard-icon {
    img {
      width: 53px;
      height: 41px;
    }
  }
  .visa-icon {
    img {
      width: 93px;
      height: 30px;
    }
  }
}

.order-placed__wrapper {
  display: flex;
  justify-content: space-between;
  padding: 20px;

  @media (max-width: 800px) {
    flex-direction: column;
  }

  @media (min-width: 1500px) {
    padding: 20px 200px;
  }

  @media (min-width: 1700px) {
    padding: 20px 260px;
  }

  @media (min-width: 1920px) {
    // min-width: 1398px;
    justify-content: flex-start;
  }

  .order-placed__container {
    width: 800px;
    margin-right: 30px;

    @media (max-width: 800px) {
      width: 100%;
    }
    @media (min-width: 1920px) {
      margin-right: 120px;
    }
    >p {
      font-size: 16px;
      line-height: 23px;
      white-space: pre-wrap;

      &:first-child {
        >span {
          font-weight: 700;
          color: #F74623;
        }
      }

      &:nth-child(2) {
        padding: 20px;
        background-color: #FCEEE3;
        margin: 40px 0;
        font-size: 16px;
        line-height: 19px;
        font-weight: 400;
      }

      &:last-child {
        >span {
          color: #bb6bd9;
        }
      }
    }

    .wire__container {
      padding: 0 30px;
      border: $border;
      margin: 40px 0;
      >p {
        font-size: 16px;
        line-height: 19px;
        white-space: pre-wrap;

        &:last-child {
          margin: 20px 0;
          >span {
            font-weight: 700;
          }
        }
      }
      .wire-item__title {
        margin: 30px 0 10px;
        color: #828282;
        font-size: 16px;
      }
    }
  }

  .order-info__container {
    p {
      white-space: pre-wrap;
      font-size: 16px;
      line-height: 24px;
      margin-top: 20px;
      color: #828282;

      span {
        font-weight: 700;
        color: #333333;
      }
    }
  }
}

.orders-placed-buttons__container {
  margin: 40px 0;
  display: flex;
  padding: 0 20px;
  flex-direction: column;
  align-items: flex-start;
  button:first-child {
    margin-bottom: 30px;
  }

  @media (min-width: 1700px) {
    padding: 0 260px;
  }
}

.hide {
  display: none !important;
}
