.quotes-page__wrapper {
  display: flex;

  @media (min-width: 1920px) {
    width: 1920px;
  }

  .account-info__sidebar{
    width: 260px;
    flex-shrink: 0;
  
    @media (max-width: 860px) {
      width: 180px;
  
      .sidebar-item {
        padding-left: 20px;
      }
    }

    @media (max-width: 575px) {
      width: 100%;
    }
  }

  .quotes-list__wrapper {
    flex-grow: 1;

    .quotes-list__header {
      background-color: #F5F5F7;
      display: flex;
      flex-wrap: wrap;
      .quotes-list__header-item {
        color: #828282;
        display: flex;
        height: 79px;
        justify-content: center;
        align-items: center;
        padding: 0 29px;
        border-bottom: $border;
        border-top: $border;
        border-right: $border;
        cursor: default;

        &.quotes-list__header-item_dropdown {
          position: relative;
          cursor: pointer;

          .dropdown__container {
            display: none;
            background-color: #ffffff;
            z-index: 1;
            position: absolute;
            width: 300px;
            top: 82px;
            left: 0;
            padding: 8px 0;

            >div {
              padding: 10px 30px;
              font-size: 16px;
              line-height: 30px;
              color: #333333;
              cursor: pointer;

              &.active, &:hover {
                color: #F74623;
              }
            }
          }

          &.active .dropdown__container {
            display: block;
          }
        }

        .magnifier-icon {
          margin-right: 18px;
        }

        p {
          color: #828282;
        }

        input {
          height: 20px;
          border: none;
          font-size: 16px;
          background-color: transparent;

          &.searchByQuote {
            width: 220px;
          }

          &.datesRangePicker {
            width: 330px;
            font-weight: 600;
            font-size: 18px;
            line-height: 21px;
            color: #F74623;

            @media (max-width: 640px) {
              width: 231px;
            }
          }
        }

        .select-icon {
          width: 9px;
          height: 5px;
          background-size: 9px 5px;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url("./img/select-arrow.svg");
          margin-left: 8px;
        }

        .arrow-round {
          cursor: pointer;
          background: url("./img/arrow-round.svg");
          width: 20px;
          height: 20px;
        }

        &:last-child {
          flex-grow: 1;
          justify-content: flex-end;
        }
      }
    }

    .quote-item__wrapper {
      border-bottom: $border;
      padding-left: 40px;
      padding-right: 20px;

      @media (max-width: 1280px) {
        padding-left: 30px;
      }

      @media (max-width: 800px) {
        padding-left: 20px;
      }

      &.active {
        background-color: #ffffff;
        .quote__container {
          display: block;
        }

        .list-arrow {
          transform: rotate(180deg);
        }
      }

      .quote__container {
        display: none;
        margin-left: 40px;

        .payment-info__container {
          display: flex;

          >div {
            &:first-child {
              margin-right: 40px;
            }
            >div {
              margin-bottom: 20px;
              p {
                font-size: 16px;
                line-height: 20px;

                &.title {
                  font-size: 14px;
                  color: #828282;
                  margin-bottom: 10px;
                }
              }
            }
          }

          @media (max-width: 650px) {
            flex-direction: column;
            align-items: flex-start;
          }
        }
        @media (max-width: 650px) {
          margin-left: 0;
        }
      }

      &.order-item {
        .quote-header__container {
          >div:first-child {
            .quote__date {
              margin-right: 10px;
            }
            >div:last-child {
              width: 60%;
            }

            @media (max-width: 1145px) {
              flex-direction: column;
              align-items: flex-start;

              >div:last-child {
                margin-top: 20px;
                width: auto;

                >div:first-child {
                  margin-right: 10px;
                }

                >div:last-child {
                  >div {
                    margin-right: 0;
                    &:first-child {
                      margin-right: 10px;
                    }
                  }
                }
              }
            }

            @media (max-width: 885px) {
              >div:last-child {
                flex-direction: column;
                align-items: flex-start;

                >div:last-child {
                  margin-top: 10px;
                }
              }
            }

            @media (max-width: 885px) {
              >div:last-child {
                >div:last-child {
                  flex-direction: column;
                  align-items: flex-start;

                  &.status__container {
                    width: auto;
                  }

                  >div:last-child {
                    margin-top: 10px;
                  }
                }
              }
            }
          }
        }
      }

      &.payments-item {
        .quote-header__container {
          @media (max-width: 1070px) {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
  
            a {
              margin-top: 40px;
            }
          }
          .quote-item__title {
            &.pending {
              margin-left: 40px;
            }
          }
          a {
            color: #F74623;
            font-size: 18px;
            line-height: 22px;
            font-weight: 500;
          }

          >div:first-child {
            .quote__date {
              margin-right: 10px;
            }
            >div:last-child {
              width: 66%;

              
            }

            @media (min-width: 1700px) {
              width: 1127px;
            }

            @media (max-width: 1340px) {
              flex-direction: column;
              align-items: flex-start;

              >div:last-child {
                margin-top: 20px;
                width: auto;

                >div:first-child {
                  margin-right: 10px;
                }

                >div:last-child {
                  >div {
                    margin-right: 0;
                    &:first-child {
                      margin-right: 10px;
                    }
                  }
                }
              }
            }

            @media (max-width: 952px) {
              >div:last-child {
                flex-direction: column;
                align-items: flex-start;

                >div:last-child {
                  margin-top: 10px;
                }
              }
            }
          }

          >div:last-child {
            @media (min-width: 1650px) {
              margin-right: 97px;
            }
          }
        }
      }

      .quote-header__container {
        display: flex;
        padding: 30px 0;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 620px) {
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;

          a {
            margin-top: 40px;
          }
        }

        >div:first-child {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 80%;

          @media (min-width: 1440px) {
            width: 962px;
          }

          @media (max-width: 1020px) {
            flex-direction: column;
            align-items: flex-start;
            width: auto;
            justify-content: center;
          }

          >div:last-child {
            display: flex;
            align-items: center;
            width: 55%;
            justify-content: space-between;

            @media (max-width: 1020px) {
              width: auto;
              margin-top: 20px;

              div:first-child {
                margin-right: 40px;
              }
            }
          }
        }

        >div:last-child {
          @media (min-width: 1650px) {
            margin-right: 144px;
          }
        }
      }

      .quote-item__title {
        display: flex;
        align-items: center;
        .quote__title {
          display: block;
          >div {
            color: #333333;
            margin-bottom: 10px;
            font-weight: 500;
            font-size: 36px;
            line-height: 42px;
          }

          p {
            line-height: 20px;
            color: #828282;
          }

          .subtitle {
            line-height: 20px;
            color: #828282;
            display: flex;
            align-items: center;

            .marker {
              font-size: 16px;
              margin: 0 10px
            }
          }
        }

        .list-arrow {
          margin-right: 20px;
          cursor: pointer;
          transition: .1s;
        }
      }

      .quote__date {
        font-size: 18px;
        line-height: 24px;
        color: #333333;
        flex-shrink: 0;

        &.quote__date_payment-type {
          width: 155px;
          text-align: left;
        }

        &.quote__date_amount {
          width: 150px;
          text-align: left;
        }

        &.quote__date_date {
          width: 150px;
          text-align: left;
        }
      }

      .status__container {
        width: 352px;
        display: flex;
        flex-wrap: wrap;
        &.status__container_quotes {
          width: 135px;
        }
        >div {
          margin-right: 10px;

          flex-shrink: 0;
          &:last-of-type {
            margin-right: 0;
          }
        }
      }

      .quote__status {
        padding: 10px;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        background-color: #F2F2F2;

        &.quote__status_awaiting {
          color: #333333;
        }

        &.quote__status_received {
          color: #219653;
        }

        &.quote__status_accepted {
          color: #F2994A;
        }

        &.quote__status_rejected {
          color: #828282;
        }

        &.quote__status_pending {
          color: #ffffff;
          background-color: #F2994A;
        }
      }

      a {
        color: #333333;
        text-decoration: none;
        display: flex;
        align-items: center;
  
        span {
          font-size: 18px;
          margin-right: 8px;
          line-height: 21px;
          font-weight: 400;
        }
      }

      .quote-item__container {
        display: flex;
        align-items: center;
        width: 75%;
        margin-bottom: 20px;

        .cart-item {
          padding: 0px 20px 0 10px;
          align-items: center;
        }

        @media (max-width: 1100px) {
          width: 100%;
          justify-content: space-between;
          .cart-item {
            padding-right: 0;
          }
        }

        @media (max-width: 1020px) {
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          .cart-item {
            width: 100%;

            .cart-item__info {
              flex-grow: 1;
            }
          }
        }
        .cart-item__info {
          @media (max-width: 400px) {
            width: 255px;
          }

          @media (max-width: 374px) {
            width: 200px;
          }
        }

        .delivery-info {
          display: block;
          >div {
            display: flex;
            margin: 10px 0;

            &:first-child {
              >div {
                display: flex;
                margin-top: 10px;
              }
              @media (max-width: 640px) {
                flex-direction: column;
                align-items: flex-start;
              }
            }

            .marker {
              margin: 0 10px;
            }
          }
        }

        .order-quantity {
          line-height: 19px;
          flex-shrink: 0;
          span {
            font-weight: 500;
          }

          @media (max-width: 1020px) {
            margin-left: 80px;
          }
        }
      }
    }
  }
}

.list-arrow {
  width: 20px;
  height: 12px;
  background-image: url("./img/list-arrow.svg");
}

.daterangepicker {
  font-family: 'Roboto' !important;
  margin-top: 31px !important;
  border-radius: 0 !important;
  box-shadow: 0px 3px 5px -1px #00000033, 0px 5px 8px 0px #00000024, 0px 1px 14px 0px #0000001f;
  color: #333333 !important;
  border: none !important;
  &::before, &::after {
    content: none !important;
  }
  .cancelBtn {
    @include button;
    background-color: #F2F2F2;
    color: #333333;
    &:hover {
      color: #F74623;
      transition: .3s;
    }
    &:disabled {
      opacity: .5;
      cursor: default;
      &:hover {
        background-color: #F2F2F2;
        color: #333333;
      }
    }
  }

  .applyBtn {
    @include button;
    background-color: #F74623;
    color: #FFFFFF;

    &:hover {
      background-color: #F75E23;
      transition: .3s;
    }

    &:disabled {
      opacity: .5;
      cursor: default;
      &:hover {
        background-color: #F74623;
      }
    }
  }

  .calendar-table th, .daterangepicker .calendar-table td {
    font-size: 14px !important;
  }

  .drp-calendar.left {
    padding: 20px 0 8px 16px !important;
  }

  .drp-calendar.right {
    padding: 20px 16px 8px 8px !important;
  }

  .drp-buttons {
    padding: 10px 16px !important;
  }
}