.product-page-info__wrapper {
  padding: 60px 20px;
  @media (min-width: 1700px) {
    padding: 60px 80px;
    justify-content: flex-start;
  }
  @media (max-width: 1270px) {
    padding: 60px 12px;
  }
  @media (max-width: 950px) {
    flex-direction: column;
  }
  display: flex;
  justify-content: space-between;
  .images__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    &.images__wrapper_fixed {
      position: fixed;

      @media (max-width: 1270px) {
        position: static !important;
      }
    }
    &.images__wrapper_hidden {
      @media (max-width: 1270px) {
        display: none !important;
      }
    }
    .image__container_active {
      width: 450px;
      height: 450px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: $border;
      margin-bottom: 20px;
      background-color: #ffffff;
      cursor: pointer;
      .image_active {
        // background-image: url("./img/product-image.png");
        width: 188px;
        height: 296px;
        background-repeat: no-repeat;
        background-position: center;
      }

      @media (max-width: 1320px) {
        width: 360px;
        height: 360px;
        .image_active {
          width: 150px;
          height: 237px;
          background-size: 150px 237px;
        }
      }
      @media (max-width: 950px) {
        width: 450px;
        height: 450px;
        .image_active {
          width: 188px;
          height: 296px;
          background-size: 188px 296px;
        }
      }

      @media (max-width: 500px) {
        width: 335px;
        height: 335px;
        .image_active {
          width: 140px;
          height: 220px;
          background-size: 140px 220px;
        }
      }

      @media (max-width: 350px) {
        width: 240px;
        height: 240px;
        .image_active {
          width: 140px;
          height: 220px;
          background-size: 140px 220px;
        }
      }
    }

    .images__container {
      display: flex;
      // justify-content: space-between;
      width: 286px;
      flex-wrap: wrap;
      .product-image__container {
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: $border;
        cursor: pointer;
        background-color: #ffffff;
        margin-bottom: 20px;
        margin-right: 20px;

        &:nth-child(3n) {
          margin-right: 0;
        }

        // &:nth-child(2n) .product-image {
          // background-image: url("./img/product-pic.png");
        // }

        .product-image {
          width: 33px;
          height: 52px;
          // background-image: url("./img/product-image.png");
          background-size: 33px 52px;
        }
      }
      
    }
  }

  .product-info__wrapper {
    width: 66%;
    margin-left: 40px;
    @media (min-width: 1800px) {
      margin-left: 90px;
    }
    @media (max-width: 1320px) {
      margin-left: 0;
    }
    @media (max-width: 1270px) {
      margin-left: 40px;
    }
    @media (max-width: 950px) {
      width: 100%;
      margin-left: 0;
      margin-top: 60px;
    }
    &>div:first-child {
      display: flex;
      justify-content: space-between;

      @media (min-width: 1700px) {
        justify-content: flex-start;
      }
      
      @media (max-width: 1270px) {
        flex-direction: column;
      }
      @media (max-width: 950px) {
        border-bottom: $border;
        padding-bottom: 40px;
      }
    }
    .product-info__container {
      @media (min-width: 1400px) {
        margin-right: 40px;
      }
      h2 {
        width: 520px;
        margin-bottom: 40px;
        color: #333333;
        @media (max-width: 1270px) {
          width: 100%;
        }

        @media (min-width: 1700px) {
          width: 720px;
        }
      }

      .supplier-info__container {
        display: flex;
        justify-content: space-between;
        @media (min-width: 1700px) {
          width: 550px;
        }
        @media (max-width: 1270px) {
          flex-direction: column;
          >p {
            margin-bottom: 42px;
          }
        }
        p {
          line-height: 19px;
          &:first-child {
            color: #828282;
          }
          span {
            text-decoration: underline;
            color: #F74623;
          }
        }

        >div {
          display: flex;
          cursor: pointer;
          align-items: center;
          >p {
            margin-left: 10px;
          }
        }
      }

      .product-characteristic__container {
        margin-top: 45px;
        padding-bottom: 40px;
        border-bottom: $border;
        .info__item {
          display: flex;
          margin-top: 12px;
          @media (max-width: 500px) {
            flex-wrap: wrap;

            .custom-select .select-items {
              width: 100%;
            }
          }
          >p {
            width: 160px;
            font-size: 16px;
            line-height: 30px;
            color: #828282;
            display: flex;
            align-items: center;
            align-self: flex-start;
            padding: 15px 0;
          }
          >span {
            padding: 15px 20px;
            border: $border;
            font-size: 16px;
            line-height: 30px;
          }
  
          &:first-child {
            >.toggle {
              flex-direction: column;
              align-items: flex-start;
            }
          }
          .toggle {
            @media (max-width: 500px) {
              flex-direction: column;
            }
          }
        }
      }
    }

    .lead-info__wrapper {
      position: relative;
      display: flex;
      flex-grow: 1;
    }

    .lead-info__container {
      margin-left: 20px;
      margin-top: 100px;
      right: auto;
      top: auto;

      &.lead-info__container_fixed {
        position: fixed;

        @media (max-width: 1270px) {
          position: static !important;
        }
      }

      &.lead-info__container_hidden {
        flex-grow: 1;
        @media (max-width: 1270px) {
          display: none !important;
        }
      }

      @media (max-width: 1270px) {
        margin-left: 0;
      }
      @media (max-width: 950px) {
        margin-top: 40px;
      }
      >p {
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 20px;
        display: flex;
        flex-wrap: wrap;

        >span {
          margin-right: 5px;
          color: #828282;
        }
      }

      .custom-input-number {
        margin: 40px 0 20px;
      }

      .button-primary {
        padding: 16px 65px;
      }
    }

    .description__container {
      width: 520px;
      padding-top: 40px;

      @media (min-width: 1700px) {
        width: 720px;
      }

      @media (max-width: 640px) {
        width: 100%;
      }

      >div {
        margin-bottom: 40px;
        p {
          line-height: 24px;
        }
        .description__title {
          line-height: 30px;
          color: #828282;
          margin-bottom: 20px;
        }
      }

      .documentation__container {
        >div {
          display: flex;
          @media (max-width: 425px) {
            flex-direction: column;
            align-items: flex-start;
            >a:first-child {
              margin-right: 0;
              margin-bottom: 10px;
            }
          }
          >a {
            text-decoration: none;
            padding: 20px;
            // width: 176px;
            // height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: $border;
            &:first-child {
              margin-right: 10px;
            }
            .documentation-icon {
              width: 16px;
              height: 20px;
              background-image: url("./img/pdf-icon.svg");
              margin-right: 10px;
            }
            span {
              color: #F74623;
            }
          }
        }
      }
      .brand__container {
        .brand-logo {
          height: 50px;
          display: flex;
          align-items: center;
          img {
            width: 200px;
            height: 120px;
          }
        }
        p {
          margin: 20px 0;
        }
        a {
          color: #F74623;
          text-decoration: none;
          display: flex;
          align-items: center;
          width: max-content;
  
          span {
            font-size: 16px;
            margin-right: 10px;
            font-weight: 400;
          }
        }
      }
    }
  }
}

.related-products {
  h3 {
    font-weight: 300;
    font-size: 40px;
    line-height: 52px;
    padding-bottom: 60px;
    text-align: center;
  }
}

.chat-icon {
  background-image: url("./img/chat-icon.svg");
  width: 24px;
  height: 24px;
}

.modal-contact-supplier {
  .modal__container {
    width: 800px;
    background-color: #ffffff;
    @media (max-width: 800px) {
      width: 100%;
    }
    .modal-header {
      padding: 30px 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    
    .product-info {
      background-color: #F2F2F2;
      padding: 20px 40px;

      >div {
        &:first-child {
          margin-bottom: 20px;
        }
        >p {
          &:first-child {
            font-size: 16px;
            line-height: 19px;
          }
          &:last-child {
            font-size: 13px;
            line-height: 16px;
          }
        }
      }
    }

    .input__container {
      margin: 30px 40px;
      padding: 16px 20px;
      display: flex;
      align-items: center;
      border: $border;
      @media (max-width: 500px) {
        flex-direction: column;
        align-items: flex-start;
      }

      &>div {
        display: flex;
        width: 85%;
        align-items: center;
        @media (max-width: 500px) {
          width: 100%;
          margin-bottom: 20px;
        }
      }

      input {
        width: 497px;
        height: 20px;
        border: none;
        font-size: 16px;
        line-height: 19px;
        margin-left: 19px;
        margin-right: 45px;
        &[type="file"] {
          display: none;
        }
        @media (max-width: 800px) {
          width: 90%;
        }
        @media (max-width: 500px) {
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }
}

.info-message__container {
  position: relative;

  .message__container {
    padding: 0 20px;
    align-items: center;
    box-shadow: 0px 20px 50px 0px #0000001A;
    position: fixed;
    background-color: #ffffff;
    z-index: 1;
    right: 0;
    top: 86px;
    display: none;
    height: 70px;

    &.cart-message {
      @media (max-width: 480px) {
        padding: 10px 20px;
      }
      div:first-child {
        display: flex;
        align-items: center;
        @media (max-width: 480px) {
          flex-direction: column;
          align-items: flex-start;
        }

        @media (max-width: 340px) {
          margin-right: 0;
        }

        >* {
          margin-right: 20px;
        }
    
        :last-child {
          margin-right: 0;
        }
      }
    }

    >* {
      margin-right: 20px;
    }

    :last-child {
      margin-right: 0;
    }
  }

  .visible {
    display: flex;
  }
}

.paperclip-icon {
  width: 20px;
  height: 20px;
  background-image: url("./img/paperclip-icon.png");
  cursor: pointer;
}

.close-icon {
  background-image: url("./img/cross.svg");
  width: 20px;
  height: 20px;
  cursor: pointer;
}
