.search__container_marketplace-page {
  background-color: #ffffff;
}

.popular-items__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 20px 0;
  border-top: $border;

  @media (max-width: 560px) {
    justify-content: flex-start;
  }

  >p {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
    margin-right: 16px;
  }

  >div {
    padding: 10px;
    border: $border;
    font-size: 16px;
    line-height: 24px;
    margin-right: 16px;
    cursor: pointer;
    margin-bottom: 10px;
  }
}

.marketplace-categories__wrapper {
  margin-top: 110px;

  @media (min-width: 1920px) {
    min-width: 1920px
  }

  .marketplace-categories__row {
    margin-bottom: 90px;
    display: flex;
    justify-content: flex-end;

    @media (max-width: 1024px) {
      flex-direction: column;
    }


    .marketplace-category__wrapper {
      padding: 34px 40px 34px 220px;
      flex-grow: 1;
      width: 50%;
      position: relative;
      display: flex;
      transition: .2s;
      border-top: $border;
      border-bottom: $border;
      min-height: 280px;
      
      @media (min-width: 1770px) {
        padding: 34px 40px 34px 320px;
      }

      @media (max-width: 1024px) {
        width: auto;
      }
      
      @media (max-width: 600px) {
        flex-direction: column;
        padding-left: 20px;
        padding-top: 84px;
      }

      &:first-child {
        border-right: $border;
        @media (max-width: 1024px) {
          margin-bottom: 90px;
        }
      }

      &:hover {
        box-shadow: 0px 20px 50px 0px #0000001A;
        background-color: #ffffff;
        .category__container .category__title {
          text-decoration: underline;
          color: #F74623;
        }
      }

      .category-logo {
        // width: 140px;
        // height: 140px;
        // border-radius: 50%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        background-repeat: no-repeat;
        background-position: center;
        top: -45px;
        left: 40px;background-size: contain;

        img {
          width: 140px;
          height: 140px;
          border-radius: 50%;

          @media (min-width: 1770px) {
            width: 240px;
            height: 240px;
          }
        }

        @media (max-width: 1024px) {
          left: 20px;
        }

        @media (max-width: 800px) {
          width: 120px;
          height: 120px;
        }

        @media (max-width: 425px) {
          width: 100px;
          height: 100px;
        }
      }
    }

    .category__container {
      .category__title {
        transition: .2s;
        color: #333333;
        font-weight: 500;
        font-size: 46px;
        line-height: 52px;
        text-decoration: none;

        @media (max-width: 1280px) {
          font-size: 40px;
        }
      }

      >p {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #828282;
        margin-top: 24px;

        @media (max-width: 425px) {
          display: none;
        }

        @media (min-width: 1560px) {
          width: 520px;
        }
  
      }
      

      >ul {
        margin-top: 24px;
        margin-left: 18px;
        font-size: 18px;
        line-height: 26px;
      }
    }
  }
}
