.breadcrumbs {
  padding: 30px 0 30px 40px;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #E7E8EA;

  box-sizing: border-box;
  @media(max-width: 1440px){
    padding: 30px 0 30px 20px;
  }
}

.breadcrumbs li {
  list-style: none;
  display: block;
  background: transparent url('./img/breadcrumb_point.svg') right 2px center no-repeat;
  padding-right: 16px;
  margin-right: 10px;
  font-size: 18px;
}

.breadcrumbs li:hover {
  color: rgba(247, 70, 35, 1);
  cursor: pointer;
}

.breadcrumbs li:last-child {
  color: rgba(130, 130, 130, 1);
  background: none;
  cursor: default;
  margin-right: 0;
}

.category-name__wrapper {
  padding: 60px 100px 60px 40px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #E7E8EA;

  .category-name__description {
    width: 440px;
    color: #828282;
  }
}

@media (max-width: 1440px) {
  .category-name__wrapper {
    padding: 60px 100px 60px 20px;
  }
}

@media (max-width: 1280px) {
  .category-name__wrapper {
    padding: 60px 60px 60px 20px;
  }
}

@media (max-width: 900px) {
  .category-name__wrapper {
    padding: 60px 52px 60px 20px;
    display: block;

    h1 {
      margin-bottom: 30px;
    }

    .category-name__description {
      width: 100%;
    }
  }
}

.subcategories__wrapper {
  background: rgba(245, 245, 247, 1);
  width: 100%;

}

.subcategories__filter-content {
  display: flex;
  position: relative;

  .subcategories__content {
    max-width: 1440px;

  }

}

.subcategories__switch-panel {
  display: flex;
  border: 1px solid #E7E8EA;
  background: rgba(245, 245, 247, 1);
  align-items: center;

  .filter-btn, .filter-btn-close-container {
    display: none;
    cursor: pointer;
  }

  .filter-btn-close-container {
    background-color: #FFFFFF;
    padding: 30px;
  }

  .filter-btn-close {
    background: url("./img/cross.svg");
    width: 20px;
    height: 20px;
  }

  @media(max-width: 800px) {
    .filter-btn, .filter-btn-close-container.active {
      display: block;
      margin-right: 5px;
    }
    .filter-btn.active {
      display: none;
    }
    .product-amount.active {
      width: 260px;
      border: 1px solid #E7E8EA;

      .arrow-round {
        display: block;
      }

      @media (max-width: 700px) {
        width: 190px;
      }
    }
  }

  .product-amount {
    width: 480px;
    box-sizing: border-box;
    border-right: 1px solid #E7E8EA;
    display: flex;
    padding: 29px 32px 28px 40px;

    align-items: center;
    justify-content: space-between;

    .arrow-round {
      cursor: pointer;
      background: url("./img/arrow-round.svg");
      width: 20px;
      height: 20px;
    }

    @media(max-width: 1439px) {
      max-width: 260px;
      padding: 29px 32px 28px 20px;
    }

    @media(max-width: 800px) {
      width: 170px;
      border: none;
      .arrow-round {
        display: none;
      }
    }


  }

  .switches {
    display: flex;
    width: 170px;
    margin-left: auto;

    .burger-icon-container {
      padding: 32px;
      border-left: 1px solid #E7E8EA;
      cursor: pointer;

      .burger-icon-active {
        background-image: url("./img/burger-active.svg");
        width: 18px;
        height: 14px;
        display: none;
      }

      .burger-icon-dis {
        background-image: url("./img/burger.svg");
        width: 18px;
        height: 14px;
      }
    }

    .dashboard-layout-icon-container {
      padding: 32px;
      border-left: 1px solid #E7E8EA;
      cursor: pointer;

      .dashboard-layout-icon-active {
        background: url("./img/dashboard-layout-active.svg");
        width: 15px;
        height: 15px;
        display: none;
      }

      .dashboard-layout-icon-dis {
        background: url("./img/dashboard-layout.svg");
        width: 15px;
        height: 15px;
      }
    }

    .active {
      .burger-icon-dis, .dashboard-layout-icon-dis {
        display: none;
      }

      .burger-icon-active, .dashboard-layout-icon-active {
        display: block;
      }
    }
  }

  @media(max-width: 600px) {
    .switches {
      display: none;
    }
  }
}

.select {
  position: relative;
  display: none;

}

.select.active {
  display: block;

}

.select.is-active {
  .select__icon {
    transform: rotate(180deg);
  }
}

.select.is-active .select__body {
  display: block;
}

.select__header {
  border-right: 1px solid #E7E8EA;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-right: 10px;
  @media(max-width: 800px) {
    border: none;
  }

}

.select__current {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  padding: 30px 30px 30px 25px;
  @media(max-width: 630px) {
    padding: 30px 10px 30px 10px;
  }
}

.select__icon {
  background: url("./img/arrow-down.svg");
  min-width: 11px;
  height: 8px;
  margin-left: auto;
}

.select__body {
  border: 1px solid #cccccc;
  box-sizing: border-box;
  background: #FFFFFF;
  border-top: 0;
  display: none;
  left: 0;
  position: absolute;
  z-index: 1;
  right: 0;
  top: 100%;
}

.select__item {
  cursor: pointer;
  padding: 10px 0px 10px 32px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
}

.select__item:hover {
  color: rgba(247, 70, 35, 1);
}

.subcategories__filter {
  box-sizing: border-box;
  border: 1px solid #E7E8EA;
  border-top: none;
  min-width: 480px;

  .filter-list {
    width: 100%;

    .list-item {
      cursor: pointer;
      width: 100%;
      box-sizing: border-box;
      border: 1px solid #E7E8EA;
      border-right: none;
      padding: 30px 60px 35px 40px;

      .list-item-title {
        display: flex;
        align-items: center;

        h4 {
          color: rgba(247, 70, 35, 1);
          margin-right: 5px;
        }

        .arrow-down {
          background: url("./img/arrow-down.svg");
          width: 11px;
          height: 8px;
        }
      }

      .checkboxes {
        display: block;
        margin-top: 38px;

        label {
          font-size: 18px;
          margin-bottom: 12px;
        }

        span {
          color: rgba(247, 70, 35, 1);
          margin-left: 10px;
        }
      }
    }

    .list-item.disabled .checkboxes {
      display: none;
    }
  }

  @media(max-width: 1440px) {
    min-width: 260px;
    .filter-list {
      .list-item {
        padding: 30px 10px 35px 20px;
      }
    }
  }
  @media(max-width: 700px) {
    min-width: 190px;

  }
}

.subcategories__products {
  background: #FFFFFF;
  display: flex;
  flex-wrap: wrap;
}

.product-card {
  width: 33.3%;
  box-sizing: border-box;
  padding: 40px;

  border: 1px solid #E7E8EA;
  @media(max-width: 1024px) {
    padding: 40px 20px;
  }
  @media(max-width: 800px) {
    width: 50%;
  }
  @media(max-width: 375px) {
    width: 100%;
  }

  .product-card__name-img {
    h, a {
      color: rgba(51, 51, 51, 1);
    }



    box-sizing: border-box;
  }

  .product-card__image {
    margin: 25px 0;
  }

  .product-card__description {
    box-sizing: border-box;

  }

  .product-card__producer-link {
    margin-top: 25px;

    a {
      color: #000000;
      text-decoration: none;
      font-weight: 500;
    }

    span {
      color: #333333;
      font-weight: 300;
    }
  }
}

.product-card:hover{
  cursor: pointer;
  background: #FFFFFF;
  box-shadow: 0px 20px 50px 0px #0000001A;
  .product-card__name-img{
    a{
      color:rgba(247, 70, 35, 1);
    }
  }

  .product-card__producer-link{
    a{
      text-decoration: underline;

    }
  }
}

.product-card__row {
  width: 100%;
  padding: 30px 10px 20px 60px;
  box-sizing: border-box;
  @media(max-width: 1040px) {
    padding: 30px 10px 20px 20px;
  }
  @media(max-width: 900px) {
    padding: 10px 10px 20px 20px;
  }


  .product-card__producer-link {
    margin-left: 50px;
  }

  .product-card__content {

    width: 100%;
    display: flex;
    align-items: center;

  }

  .product-card__name-img {
    width: 100%;

    h4, a {
      max-width: 280px;
      @media(max-width: 1040px) {
        max-width: 200px;
      }
      @media(max-width: 900px) {
        min-width: 360px;
      }
    }
  }

  .product-card__image {
    margin-right: 10px;
  }

  .product-card__description {
    margin-left: 40px;
  }

  .product-card__name-img {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 480px;
    @media(max-width: 900px) {
      display: block;
      flex-direction: column;
      width: 200px;
      .product-card__name-img {
        h4, a {
          width: 280px;
        }
      }
    }

  }
}

@media(max-width: 800px) {
  .subcategories__filter {
    display: none;
  }
  .subcategories__filter.active {
    display: block;
    position: absolute;
    width: 260px;
    background: rgba(245, 245, 247, 1);
    z-index: 0;
    top: 0;
    left: 0;
    bottom: 0;
    @media(max-width: 700px) {
      width: 190px;
      .filter-list {
        .list-item {
          padding: 30px 40px 35px 20px;
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .subcategories__filter.active {
    width: 190px;
  }
}

.subcategories__load-more {
  margin-bottom: 100px;
}

.source-request__wrapper {
  background: #251E38;
  width: 100%;

  .source-request__content {
    opacity: 1;
    background: url("./img/bg-source-request.png") no-repeat center top/cover;
    height: 100%;
    padding: 60px 0 60px 100px;

    .source-request__title {
      color: rgba(247, 70, 35, 1);
      margin-bottom: 40px;
    }

    .source-request__text {
      width: 600px;

    }

    .source-request__text p {
      color: #fff;
    }

    .source-request__text p:last-child {
      margin: 24px 0 40px;
    }

    .source-request__link {
      display: flex;
      align-items: center;
    }

    .source-request__link a {
      text-decoration: none;
      color: white;
      font-family: 18px;
      font-weight: 500;
      line-height: 21px;
    }

    .source-request__link a:hover {
      color: rgba(247, 70, 35, 1);
      text-decoration: underline;
    }

    .link-arrow {
      background: url("./img/arrow-right-orange.svg");
      width: 20px;
      height: 20px;
      margin-left: 10px;
    }
  }
}

@media(max-width: 690px) {
  .source-request__wrapper {
    .source-request__content {
      padding: 60px 0 60px 60px;

      .source-request__text {
        width: 520px;
      }
    }
  }
}

@media(max-width: 560px) {
  .source-request__wrapper {
    .source-request__content {
      padding: 60px 0 60px 20px;

      .source-request__text {
        width: 335px;
      }
    }
  }
}

@media(max-width: 375px) {
  .source-request__wrapper {
    .source-request__content {
      padding: 60px 0 60px 20px;

      .source-request__text {
        width: 280px;
      }
    }
  }
}

.pagination{
  padding: 30px 50px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border: 1px solid #E7E8EA;
  border-left:none;
  border-top: none;

  margin-bottom: 20px;

  @media(max-width: 440px){
    padding: 30px 20px;
  }
  @media(max-width: 375px){
    padding: 30px 5px;
    .step-back,.step-next{
    p{
      display: none;
    }
    }
  }
  div{
    display: flex;
    align-items: center;
  }

  .page-numbers{
    a{
      text-decoration: none;
      margin-right: 15px;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      cursor: pointer;
    }
    a:last-child{
      margin-right: 0;
    }
    a.active{
      color:#F74623;
    }
  }

  .step-back,.step-next{
    cursor: pointer;
  }

  p{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
  }
  .arrow-back-btn{
    background: url("./img/arrow-left-icon.svg") no-repeat;
    min-width: 16px;
    height: 18px;
    margin-right: 10px;
  }

  .arrow-next-btn{
    background: url("./img/arrow-right-icon.svg") no-repeat;
    min-width: 16px;
    height: 18px;
    margin-left: 10px;
  }


  .step-next:hover{
    .arrow-next-btn{
      background: url("./img/arrow-right-hover.svg") no-repeat;
    }

    p{
      color:#F74623;
    }
  }
  .step-back:hover{
    .arrow-back-btn{
      background: url("./img/arrow-left-hover.svg") no-repeat;
    }
    p{
      color:#F74623;
    }
  }



}
