.faq {
  max-width: 1440px;
  padding-top: 60px;
  margin: 0 auto;
  @media(max-width: 1440px){
    padding-left: 40px;
  }
  .faq__title {
    margin-bottom: 60px;
  }

  .faq__modified-data {
    color: #333333;
  }

  .questions__title {
    margin: 60px 0px 30px 0px;
  }

  .order-state {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 30px;
  }

  .message-block {
    box-sizing: border-box;
    background: rgba(252, 238, 227, 1);
    width: 118px;
    padding: 20px;
    margin:50px 0px;
  }

  .question {
    margin-bottom: 30px;
    max-width: 800px;

    .question__title {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      cursor: pointer;

      .open-btn {
        background: url("./img/plus-orange-small.svg") no-repeat;
        min-width: 20px;
        height: 20px;
        margin-right: 20px;
      }

      .close-btn {
        display: none;
        background: url("./img/cross-orange.svg") no-repeat;
        min-width: 30px;
        height: 30px;
        margin-right: 20px;
      }
    }

    .question__body {
      display: none;
    }
  }

  .question.active {
    .open-btn {
      display: none;
    }

    .close-btn {
      display: block;
    }

    .question__body {
      display: block;
    }
  }
}