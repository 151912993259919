.messages__container {
  max-width: 1400px;
  margin: 0 auto 100px auto;
  display: flex;
  padding-top: 60px;
  flex: 0 1 auto;

  .messenger {
    max-width: 920px;
    margin: 0;
    overflow: auto;
    .input__container{
      position: relative;
    }
    .messenger__header{
      .receiver__name,.order__name{
        text-decoration: underline;
      }

    }

    .arrow-btn-back {
      display: none;
      background: url('./img/arrow-left-orange.svg') no-repeat;
      min-width: 20px;
      height: 20px;
      position: absolute;
      top: 30%;

    }

    .arrow-btn__container {
      position: relative;
      display: none;
      padding: 15px;
      width: 50px;
      height: 50px;
      box-sizing: border-box;
      background: #F74623;

      .arrow-btn-icon-white {
        top: 15px;
      }

    }

    @media(max-width: 640px) {
      .receiver {
        p {
          margin-left: 30px;
        }
      }
      .arrow-btn-back {
        display: block;
      }
    }

    @media(max-width: 375px) {
      .arrow-btn__container {
        display: block;

        .arrow-btn-icon-white {
          display: block;
        }
      }
      .button-primary {
        display: none;
      }
    }
  }

  @media(max-width: 640px) {
    flex-wrap: wrap;
    padding: 60px 20px 0px 20px;
    .messenger {
      margin-top: 140px;
    }
  }

}

.messages-list {
  width: 480px;

  .messages-list__item {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding: 18px 0px 18px 21px;
    border: 1px solid #E7E8EA;
    border-top: none;
    border-right: none;


    .recipient-name {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 10px;
    }

    .last-message {
      font-size: 14px;
      line-height: 16px;
      font-weight: normal;
      color: #333333;
    }

    .indicator-new-message {
      background: url("./img/point-orange.svg") no-repeat;
      background-size: 8px;
      min-width: 9px;
      height: 9px;
      position: absolute;
      right: 5px;
      top: 46%;
    }

  }

  .messages-list__item.selected {
    background: rgba(247, 70, 35, 1);

    .recipient-name, .last-message {
      color: #FFFFFF;
    }
  }

  @media(max-width: 1000px) {
    min-width: 275px;
  }
  @media(max-width: 640px) {
    width: 100%;
    .messages-list__item{
      border-right:1px solid #E7E8EA;;
    }
  }
  @media(max-width: 640px) {
    .arrow-btn-icon-orange {
      display: block;
    }
    .messages-list__item.selected {
      .arrow-btn-icon-orange {
        display: none;
      }

      .arrow-btn-icon-white {
        display: block;
      }
    }
  }
}

.arrow-btn-icon-white {
  display: none;
  background: url('./img/arrow-right-white.svg') no-repeat;
  min-width: 20px;
  height: 20px;
  position: absolute;
  right: 25px;
  top: 40%;
}

.arrow-btn-icon-orange {
  background: url('./img/arrow-right-orange.svg') no-repeat;
  display: none;
  min-width: 20px;
  height: 20px;
  position: absolute;
  right: 25px;
  top: 40%;
}
.arrow-btn__container{
  .arrow-btn-icon-white{
    right:15px;
  }
}
.attached-file{
  display: flex;
  position:absolute;
  top:-25px;
  left:20px;
  p{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #F74623;
  }
  .cross-btn{
    background: url("./img/cross-btn-small.svg") no-repeat;
    min-width: 15px;
    height: 15px;
    cursor: pointer;
    margin-left: 10px;
  }
}
