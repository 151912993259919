.company-logo__wrapper {
  background: #4B0082;
  width: 100%;


  .company-logo__content {
    background: url("./img/bg-company-logo.png") no-repeat center top/cover;
    padding: 220px 260px;
    height: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    @media(max-width: 1670px) {
      padding: 200px 150px;
    }

    @media(max-width: 1440px) {
      padding: 200px 100px;
    }

    .company-logo__images {
      display: block;
      max-width: 100%;
      height: auto;
    }

    .company-logo__text {
      width: 680px;

      p {
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 130%;
        color: white;
      }
    }

    @media(max-width: 1280px) {
      padding: 130px 60px;
      .company-logo__text {
        width: 560px;
      }
    }
    @media(max-width: 1200px) {
      flex-wrap: wrap;
      .company-logo__text {
        margin-top: 70px;
      }
    }

    @media(max-width: 550px) {
      padding: 100px 20px;
      .company-logo__images {
        background-size: 100% auto;
      }
      .company-logo__text {
        margin-top: 20px;
      }
    }
  }
}

.company-account {
  max-width: 920px;
  margin: 0 auto;
  padding: 50px 0;

  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
  }

  .signature {
    padding-top: 30px;
    border-top: 1px solid #E7E8EA;
    margin-top: 30px;
    display: inline-block;

    p {
      font-style: italic;
      font-weight: 300;
      font-size: 18px;
      line-height: 24px;
      color: #333333;
    }
  }

  @media(max-width: 1000px) {
    padding: 50px 20px;
  }
}

.company-qualities {

  background: rgba(245, 245, 247, 1);
  border: 1px solid #E7E8EA;

  .company-qualities__item {
    max-width: 920px;
    margin: 80px auto 0 auto;
    display: flex;
    justify-content: space-between;

    .mission-icon {
      margin-bottom: 20px;
      margin-left: 30px;
    }

    .vision-icon {
      margin-bottom: 20px;
    }

    .values-icon {
      margin-bottom: 20px;
    }

    .text {
      padding-top: 180px;

      li {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 40px;
        list-style: none;
        color: #333333;
        background: transparent url('./img/breadcrumb_point.svg') left top 9px no-repeat;
        padding-left: 25px;
        span{
          font-style: italic;
          font-weight: 300;
        }
      }

    }

    div:first-child {
      margin-right: 100px;
      min-width: 200px;
    }

    @media(max-width: 1024px) {
      div:first-child {
        margin-right: 56px;
      }
    }
    @media(max-width: 1000px) {
      padding: 0 20px;
    }
    @media(max-width: 640px) {
      flex-wrap: wrap;
      .text {
        padding-top: 20px;
      }
    }
  }

  .company-qualities__item.vision {
    h2 {
      margin-left: 30px;

    }
  }

  .company-qualities__item.values {
    h2 {
      margin-left: 30px;
    }
  }

}

.company-description__wrapper {
  background: #F74623;
  width: 100%;

  .company-description__content {
    background: url("./img/company-description-img.png") no-repeat center top/cover;
    height: 100%;
    padding: 160px 500px 170px 500px;
    box-sizing: border-box;
    @media(max-width: 1440px){
      padding: 160px 40px 170px 100px;
    }
    .company-description__title {
      h2 {
        color: #FFFFFF;
        margin-bottom: 40px;
      }
    }

    .company-description__text {
      p {
        color: white;
        margin-bottom: 20px;
        max-width: 920px;
      }
    }

    @media(max-width: 1280px) {
      padding-left: 60px;
    }
    @media(max-width: 400px) {
      padding-bottom: 70px;
    }
  }
}

.keep-up {
  padding: 80px 0px;
  border: 1px solid #E7E8EA;

  h2 {
    max-width: 255px;
    display: block;
    margin: 0 auto;
  }
}

.company-contact-info {
  display: flex;

  .contact-item {
    cursor: pointer;
    width: 33.3%;
    border-right: 1px solid #E7E8EA;
    box-sizing: border-box;
    padding: 55px 20px;

    .contact-item__title {
      color: #333333;
      margin-bottom: 25px;
    }

    .contact-item__text {
      color: #828282;
    }

    .contact-item__icons {
      display: flex;
      justify-content: space-between;
      max-width: 64px;
      align-items: center;
      margin-top: 24px;

      .youtube-icon {
        background: url("./img/youtube-icon-orange.svg");
        min-width: 20px;
        height: 14px;
      }

      .linkedIn-icon {
        background: url("./img/linkedIn-icon-orange.svg");
        min-width: 20px;
        height: 20px;
      }
    }

    .contact-item__link {
      margin-top: 25px;
      display: flex;
      align-items: center;
    }

    .contact-item__link a {
      text-decoration: none;
      color: rgba(247, 70, 35, 1);
      margin-right: 10px;
    }



    .arrow-right-orange {
      background: url("./img/arrow-right-orange.svg");
      min-width: 20px;
      height: 20px;
    }
  }
  .contact-item:first-child{
    border-left:1px solid #E7E8EA;

  }
  .contact-item:hover{
    background-color: #ffffff;
    box-shadow: 0px 20px 50px 0px #0000001A;
    .contact-item__title{
      color:rgba(247, 70, 35, 1);
    }
    .contact-item__link a{
      text-decoration: underline;
    }

  }
  @media(max-width: 640px) {
    display: block;
    .contact-item {
      width: 100%;
    }
  }
}

.indent {
  height: 80px;
  border: 1px solid #E7E8EA;
}
