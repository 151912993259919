.buyers-question__body {
  display: none;
}

.buyers-question.open {
  .buyers-question__body {
    display: block;
  }

  .buyers-question__title {
    .open-btn {
      animation:spin 4s linear infinite;
      transform: rotate(45deg);
    }
    h4 {
      color: #F74623;
    }
  }
}

.buyers-register__wrapper {
  background: #F75E23 url('./img/buyers-bg-elem-right.png') no-repeat right top;
  background-size: contain;
  width: 100%;

  &.suppliers-register__wrapper {
    background: #619FE7 url('./img/suppliers-bg-elem-right.png') no-repeat right top;

    @media screen and (max-width: 1740px) {
      background: #619FE7 url('./img/suppliers-bg-elem-right.png') no-repeat right -200px top;
    }

    @media screen and (max-width: 1320px) {
      background: #619FE7 url('./img/suppliers-bg-elem-right.png') no-repeat right -300px top;
    }

    @media screen and (max-width: 1230px) {
      background: #619FE7;
    }
  }

  .buyers-register__content {
    padding: 150px 140px 111px 260px;
    display: flex;
    flex-direction: column;
    max-width: 720px;

    h2 {
      color: #ffffff;
      margin-bottom: 44px;
      font-size: 36px;
      line-height: 52px;
    }

    p {
      color: #ffffff;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 31px;
      letter-spacing: 0;
      text-align: left;
      margin-bottom: 56px;
    }

    a {
      color: #ffffff;
      text-decoration: none;
      display: flex;
      align-items: center;

      span {
        font-size: 18px;
        line-height: 21px;
        margin-right: 10px;
      }
    }

    @media (max-width: 1440px) {
      padding: 90px 90px 51px 200px;
      max-width: 100%;
    }
    @media (max-width: 1200px) {
      padding: 90px 90px 51px 90px;
      h2 {
        margin-bottom: 36px;
        font-size: 30px;
        line-height: 37px;
      }

      p {
        margin-bottom: 40px;
      }
    }
    @media (max-width: 768px) {
      max-width: 100%;
      padding: 50px 20px;

      h2 {
        font-size: 23px;
      }

      p {
        font-size: 18px;
      }
      a {
        span {
          font-size: 15px;
        }
      }
    }
    @media (max-width: 570px) {
      max-width: 100%;

      h2 {
        font-size: 22px;
        line-height: 31px;
      }

      p {
        font-size: 17px;
      }
      a {
        span {
          font-size: 15px;
        }
      }

    }
    @media (max-width: 320px) {
      h2 {
        line-height: 24px;
      }
      p {
        margin-bottom: 17px;
      }
      a {
        span {
          font-size: 14px;
        }
      }

    }
  }
}

.buyers-support__wrapper {
  display: flex;
  overflow: hidden;
  padding: 100px 0;
  border: 1px solid #E7E8EA;
  position: relative;

  .buyers-support__mock-image {
    position: absolute;
    right: -350px;
  }

  .buyers-support__content {
    padding: 0 160px 0 260px;
    max-width: 800px;
    width: 100%;
    flex-shrink: 0;

    h3 {
      color: #333333;
      margin-bottom: 60px;
      font-size: 30px;
      line-height: 39px;
      max-width: 800px;
    }

    .advantages__container {
      max-width: 650px;

      .advantage__container {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 40px;

        span {
          color: #F74623;
          margin-right: 10px;
        }
      }
    }
  }

  .buyers-support__mock-image {
    height: 460px;
    border-radius: 20px;
    border: 1px solid #E0E0E040;
    box-sizing: border-box;
    filter: drop-shadow(0px 20px 50px rgba(0, 0, 0, 0.1));

    @media (max-width: 1024px) {
      display: none;
    }
  }

  @media (max-width: 1200px) {
    padding: 90px 90px 51px 90px;
  }
  @media (max-width: 1028px) {
    padding-top: 70px;
    .buyers-support__mock-image {
      display: none;
    }
    .buyers-support__content h3 {
      margin-bottom: 40px;
    }
  }
  @media (max-width: 768px) {
    padding: 50px 20px 10px 20px;
    .buyers-support__content {
      margin: 0;
    }
    .buyers-support__content h3 {
      font-size: 23px;
      margin-bottom: 30px;
      line-height: 37px;
      max-width: 100%;
    }
    .buyers-support__content .advantages__container {
      max-width: 100%;

      .advantage__container {
        font-size: 16px;
      }
    }
  }
  @media (max-width: 425px) {
    padding-top: 20px;
    .buyers-support__content h3 {
      font-size: 22px;
      line-height: 24px;
      margin-bottom: 21px;
    }
    .buyers-support__content .advantages__container {

      .advantage__container {
        //font-size: 14px;
        margin-bottom: 13px;
      }
    }
  }
}

.buyers-benefits__wrapper {
  padding-left: 260px;
  border: 1px solid #E7E8EA;

  h3 {
    padding: 100px 0;
  }

  .buyers-benefits__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 100px 40px;
    max-width: 1160px;

    .buyers-benefit__item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: 560px;
      border-bottom: 1px solid #E7E8EA;

      div {
        display: flex;
        align-items: flex-end;

        img {
          width: 40px;
          height: 40px;
          padding: 0 30px 0 0;
        }

        h4 {
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 23px;
          letter-spacing: 0;
          text-align: left;
        }
      }

      p {
        padding: 30px 0;
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;

        span {
          font-weight: 300;
          font-style: italic;
        }
      }
    }
  }

  .buyers-benefit__link {
    margin: 79px 0 100px 0;
    display: flex;
    align-items: center;

    a {
      text-decoration: none;
      color: #F74623;
      margin-right: 10px;
      font-size: 18px;
      line-height: 21px;
    }

    .arrow-right-orange {
      background: url("./img/arrow-right-orange.svg");
      min-width: 20px;
      height: 20px;
    }

  }

  @media (max-width: 1440px) {
    padding-left: 200px;
    h3 {
      padding: 50px 0;
    }

    .buyers-benefits__content {
      grid-template-columns: 1fr;
      gap: 50px 10px;
    }
  }
  @media (max-width: 1200px) {
    padding: 0 90px;
  }
  @media (max-width: 768px) {
    padding: 0 20px;
    h3 {
      font-size: 24px;
      line-height: 27px;
      padding: 40px 0;
    }
    .buyers-benefit__link {
      margin: 50px 0;
      flex-wrap: nowrap;
    }
  }
  @media (max-width: 425px) {
    .buyers-benefits__content {
      gap: 25px 10px;
    }
    .buyers-benefits__content .buyers-benefit__item {
      max-width: 100%;
    }

    .buyers-benefits__content .buyers-benefit__item div img {
      width: 30px;
      height: 30px;
      padding-right: 20px;
    }
    .buyers-benefits__content .buyers-benefit__item p {
      padding: 20px 0;
    }
  }
  @media (max-width: 320px) {
    h3 {
      font-size: 21px;
      padding: 30px 0;
    }
  }
}

.buyers-faq__wrapper {
  padding-left: 260px;
  padding-bottom: 100px;
  border: 1px solid #E7E8EA;

  .buyers-faq__content {
    h3 {
      padding: 100px 0 60px 0;
      color: #333333;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 39px;
      letter-spacing: 0;
      text-align: left;

    }

    .buyers-questions__container {
      padding-bottom: 80px;

      .buyers-question {
        margin-bottom: 30px;
        max-width: 800px;

        .buyers-question__title {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          cursor: pointer;

          .open-btn {
            background: url("./img/plus-orange-small.svg") no-repeat;
            min-width: 20px;
            height: 20px;
            margin-right: 20px;
            @media (max-width: 425px) {
              margin-right: 10px;
            }
          }

          .close-btn {
            display: none;
            background: url("./img/cross-orange.svg") no-repeat;
            min-width: 30px;
            height: 30px;
            margin-right: 20px;
            @media (max-width: 425px) {
              margin-right: 10px;
            }
          }

          h4 {
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 23px;
            letter-spacing: 0;
            text-align: left;
          }

          .open__title {
            color: #F74623
          }
        }


        .buyers-question__body {
          //display: none;

          p {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0;
            text-align: left;

            img {
              display: inline;
              vertical-align: text-bottom;
            }
          }

          ul {
            padding-top: 20px;
            padding-left: 20px;

            li {
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0;
              text-align: left;

            }
          }
        }
      }
    }

    .buyers-question.active {
      .open-btn {
        display: none;
      }

      .close-btn {
        display: block;
      }

      .question__body {
        display: block;
      }
    }
  }

  .contact__item {
    width: 640px;
    border: 1px solid #E7E8EA;
    box-sizing: border-box;
    padding: 55px 20px;

    .contact__title {
      color: #333333;
      margin-bottom: 25px;
    }

    .contact__text {
      color: #828282;
    }

    .contact__link {
      margin-top: 25px;
      display: flex;
      align-items: center;

      a {
        text-decoration: none;
        color: rgba(247, 70, 35, 1);
        margin-right: 10px;
      }

      .arrow-right-orange {
        background: url("./img/arrow-right-orange.svg");
        min-width: 20px;
        height: 20px;
      }

    }

    .contact__item:first-child {
      border-left: 1px solid #E7E8EA;

    }

    .contact__item:hover {
      background-color: #ffffff;
      box-shadow: 0px 20px 50px 0px #0000001A;

      .contact__title {
        color: rgba(247, 70, 35, 1);
      }

      .contact-item__link a {
        text-decoration: underline;
      }

    }
  }

  @media (max-width: 1440px) {
    padding-left: 200px;
    .buyers-faq__content .buyers-questions__container {
      padding: 40px 0;
    }
  }
  @media (max-width: 1440px) {
    padding-left: 200px;
  }
  @media (max-width: 1200px) {
    padding: 0 90px;
    .contact__item {
      padding: 30px 10px;
      margin-bottom: 20px;
    }
  }
  @media (max-width: 1024px) {
    .buyers-faq__content h3 {
      padding: 30px 0;
    }
  }
  @media (max-width: 768px) {
    padding: 0 20px;
    .contact__item {
      width: 100%;
    }
  }
  @media (max-width: 425px) {
    .buyers-faq__content h3 {
      font-size: 28px;
      line-height: 30px;
    }
  }

  .buyers-faq__content .buyers-questions__container {
    padding: 0;
  }
}


@media screen and (max-width: 1700px){
  .buyers-support__wrapper {
    .buyers-support__content {
      max-width: 600px;
    }
  }
}

@media screen and (max-width: 1540px){
  .buyers-register__wrapper .buyers-register__content, .buyers-support__wrapper .buyers-support__content, .buyers-benefits__wrapper, .buyers-faq__wrapper {
    padding-left: 100px;
  }
}

@media screen and (max-width: 1440px){
  h3 {
    font-size: 25px;
  }

  .buyers-register__wrapper .buyers-register__content {
    max-width: 610px;

    h2 {
      font-size: 32px;
      line-height: 40px;
    }

    p {
      font-size: 18px;
    }
  }

  .buyers-support__wrapper {
    padding-top: 50px;

    .buyers-support__mock-image {
      height: 400px;
    }

    .buyers-support__content {
      h3 {
        margin-bottom: 60px;
        font-size: 25px;
        line-height: 39px;
      }

      .advantages__container .advantage__container {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 25px;
      }
    }
  }

  .buyers-benefits__wrapper {
    .buyers-benefit__link {
      margin-top: 50px;
      a {
        font-size: 16px;
      }
    }

    .buyers-benefits__content .buyers-benefit__item {
      max-width: 100%;

      p {
        font-size: 16px;
      }
    }
  }

  .buyers-register__wrapper .buyers-register__content a span {
    font-size: 16px;
  }

  .buyers-faq__wrapper .buyers-faq__content h3 {
    font-size: 25px;
    padding-top: 50px;
  }
}

@media screen and (max-width: 1300px) {
  .buyers-support__wrapper .buyers-support__mock-image {
    height: 360px;
  }
}

@media screen and (max-width: 1200px) {
  .buyers-register__wrapper {
    background-image: none;

    .buyers-register__content {
      max-width: 100%;
    }
  }

  .buyers-support__wrapper {
    padding-left: 0;
    padding-right: 0;
  }

  .buyers-register__wrapper .buyers-register__content, .buyers-support__wrapper .buyers-support__content, .buyers-benefits__wrapper, .buyers-faq__wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 1100px) {
  .buyers-support__wrapper {
    .buyers-support__mock-image {
      display: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .buyers-support__wrapper .buyers-support__content {
    max-width: 400px;
  }
}

@media screen and (max-width: 500px) {
  .buyers-support__wrapper .buyers-support__content {
    max-width: 280px;
  }
}