.profile-page__wrapper {
  display: flex;

  @media (min-width: 1920px) {
    width: 1920px;
  }

  .profile__wrapper {
    flex-grow: 1;
    background-color: #ffffff;
    align-items: flex-end;

    .form__wrapper {
      flex-grow: 1;
      padding: 60px;
      display: flex;
      flex-direction: row-reverse;
      border-bottom: $border;

      @media (max-width: 425px) {
        padding: 60px 20px;
      }

      .form__container {
        max-width: 600px;
  
        @media (max-width: 640px) {
          width: 100%;
        }
  
        .radio__wrapper {
          >span {
            font-size: 16px;
            line-height: 19px;
            color: #828282;
          } 
          .radio__container {
            margin-top: 20px;
            width: 175px;
            display: flex;
            justify-content: space-between;
  
            >label {
              font-size: 16px;
              line-height: 24px;
              &::before {
                width: 22px;
                height: 22px;
                margin-right: 16px;
                background-size: auto;
              }
            }
          }
        }
  
        .form-item {
          margin-top: 20px;
        }
  
        .checkbox__container {
          margin-top: 20px;
  
          >label {
            font-size: 16px;
            line-height: 24px;
            &::before {
              width: 22px;
              height: 22px;
              margin-right: 16px;
              background-size: auto;
            }
          }
  
          .custom-checkbox + label {
            align-items: flex-start;
          }
        }
  
        .button-primary {
          margin: 40px 0;
        }

        .lock-icon {
          width: 24px;
          height: 24px;
          position: absolute;
          top: 12px;
          right: 12px;
          background-image: url("./img/lock-icon.png");
        }
      }

      .img__wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-grow: 1;
        flex-shrink: 0;
        margin-left: 100px;
        .img__container {
          // width: 200px;
          // height: 200px;
          // background-image: url("./img/profile-img.png");
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 200px;
            height: 200px;
            border-radius: 50%;
          }
        }

        .button__container {
          margin-top: 28px;
          display: flex;
          align-items: center;

          input[type="file"] {
            display: none;
          }
        }
      }

      @media (max-width: 1000px) {
        flex-direction: column;

        .img__wrapper {
          margin-bottom: 50px;
          margin-left: 0;
        }
      }
    }

    .question-icon {
      position: relative;
      color: #F74623;
      border: $border;
      width: 18px;
      height: 18px;
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      padding: 0;
      margin-right: 0;
      margin-left: 12px;
      cursor: default;

      &:hover .question-tooltip {
        display: block;
      }

      .exclamation-sign {
        width: 2px;
        height: 10px;
        background-image: url("./img/exclamation-mark.svg");
      }

      .question-tooltip {
        display: none;
        position: absolute;
        font-size: 13px;
        line-height: 15px;
        color: #777777;
        background-color: #ffffff;
        width: 200px;
        padding: 10px 20px;
        margin: 0;
        box-shadow: 0px 20px 50px 0px #0000001A;
        // left: 32px;
        right: -3px;
        top: 46px;
      }
    }
  }

  .addresses__container {
    padding: 40px 60px;

    @media (max-width: 425px) {
      padding: 40px 20px;
    }

    .addresses__title {
      font-size: 16px;
      line-height: 19px;
      color: #828282;
    }

    .address__wrapper {
      margin: 20px 0;
      padding: 30px 20px;
      background-color: #F2F2F2;
      max-width: 420px;
      display: flex;
      justify-content: space-between;

      .address__container {
        list-style: none;
        color: #333333;

        li {
          font-size: 16px;
          line-height: 24px;
        }
      }

      .icons__container {
        display: flex;
        flex-direction: column;
        height: 68px;
        justify-content: space-between;
        margin-left: 20px;
        .edit-icon {
          width: 24px;
          height: 24px;
          background-image: url("./img/edit.svg");
          cursor: pointer;

          &:hover {
            background-image: url("./img/edit-hover.svg");
          }
        }

        .delete-icon {
          width: 24px;
          height: 24px;
          background-image: url("./img/del.svg");
          cursor: pointer;
        }
      }
    }
  }
}

.modal-add-address, .modal-edit-address {
  .modal__container {
    width: 800px;
    background-color: #ffffff;
    overflow: auto;
    max-height: 95vh;
    @media (max-width: 800px) {
      width: 100%;
    }
    .modal-header {
      padding: 30px 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    
    .product-info {
      background-color: #F2F2F2;
      padding: 20px 40px;

      >div {
        &:first-child {
          margin-bottom: 20px;
        }
        >p {
          &:first-child {
            font-size: 16px;
            line-height: 19px;
          }
          &:last-child {
            font-size: 13px;
            line-height: 16px;
          }
        }
      }
    }

    .form-address {
      display: block;
      padding: 0 40px 40px;

      &>div {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        @media (max-width: 630px) {
          flex-direction: column;
          align-items: flex-start;

          &.two-items>div:first-child {
            margin-bottom: 20px;
          }
        }

        .custom-select {
          &:first-child {
            margin-right: 30px;
          }
        }

        .form-item {
          input {
            &:first-child {
              margin-right: 30px;
            }
            &:disabled {
              margin-right: 0;
            }
          }
        }
      }

      .custom-select {
        width: 300px;

        .select-items {
          max-height: 160px;
          overflow-y: auto;
        }

        .select-selected {
          border: $border;
          width: 270px;
          padding: 16px 14px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #828282;
          // color: #333333;

          @media (max-width: 1200px) {
            width: 240px;
          }

          @media (max-width: 700px) {
            width: 216px;
          }

          &.select-open {
            border-color: #828282;
          }

          &:hover {
            border-color: #BDBDBD;
          }
        }

        .select-icon {
          width: 11px;
          height: 7px;
          background-image: url("./img/select-arrow.svg");
        }
      }
    }
  }
}