.name__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;

  .quote-status {
    padding: 10px;
    background: #F2F2F2;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  .status__container {
    display: flex;

    >div:first-child {
      margin-right: 10px;
    }
  }

  .awaiting {
    color: #333333;
  }

  .received {
    color: rgba(33, 150, 83, 1);
  }

  .rejected {
    color: #828282;
  }

  .accepted {
    color: #F2994A;
  }

  .pending {
    color: #ffffff;
    background-color: #F2994A;
  }

  @media(max-width: 1550px) {
    matgin-left: 20px;
  }
  @media(max-width: 640px) {
    flex-wrap: wrap;
    .quote-status {
      margin-top: 30px;
    }
  }
}

.quote-messenger{
  max-width: 1440px;
  margin:0 auto;
}

.quote__wrapper {
  display: flex;
  background: rgba(251, 251, 253, 1);
  margin-top: 20px;
  box-sizing: border-box;
  @media(max-width: 900px) {
    display: block;
    padding: 10px;
  }
}

.delivery-info__container {
  padding: 30px 20px 0 20px;
  .info__container {
    display: flex;
    padding: 30px 20px;
    background-color: #FCEEE3;
    width: 95%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    @media (max-width: 620px) {
      flex-direction: column;
      align-items: flex-start;
    }

    >p {
      font-size: 18px;
      line-height: 22px;
      margin-right: 10px;

      >span {
        font-weight: 500;
      }
    }

    >button {
      flex-shrink: 0;
    }

    >div {
      text-decoration: none;
      font-size: 18px;
      line-height: 22px;
      color: #F74623;
      cursor: pointer;
    }
  }
}

.quote__content {
  width: 860px;
  box-sizing: border-box;
  @media(max-width: 1115px) {
    width: 100%;
  }

  .payment-info__wrapper {
    padding: 0px 0px 30px 20px;

    &.pending-payment-page {
      margin-top: 60px;
    }
    >p {
      font-size: 16px;
      line-height: 20px;

      &.main-title {
        font-size: 16px;
        color: #828282;
        margin-bottom: 20px;
      }
    }
    .payment-info__container {
      display: flex;
      margin-bottom: 60px;
      >div {
        width: 50%;
        &:first-child {
          margin-right: 40px;
        }
        >div {
          margin-bottom: 20px;
          p {
            font-size: 16px;
            line-height: 20px;

            &.title {
              font-size: 14px;
              color: #828282;
              margin-bottom: 10px;
            }

            &.yellow-text {
              color: #F2994A;
            }
          }
        }

        @media (max-width: 650px) {
          width: 100%;
        }
      }

      @media (max-width: 650px) {
        flex-direction: column;
        align-items: flex-start;
      }

      .address__container {
        list-style: none;
        color: #333333;

        li {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    .order-items__container {
      padding-right: 30px;
      @media (max-width: 605px) {
        padding-right: 0;
      }
      .quote-item {
        justify-content: space-between;
        padding: 0;

        @media (max-width: 605px) {
          margin-bottom: 20px;
        }
        .quote-item__info {
          margin-top: 0;
          @media (max-width: 605px) {
            margin-bottom: 10px;
            width: 100%;
          }
        }
        p.title {
          color: #333333;
          margin-bottom: 0;
        }

        .quote-item__price {
          @media (max-width: 605px) {
            >div:first-child {
              margin-right: 0;
            }
            p {
              text-align: left;
            }
          }
        }
      }
    }
  }

  .documentation__container {
    padding: 60px 0px 30px 20px;
    >p {
      font-size: 16px;
      line-height: 20px;

      &.main-title {
        font-size: 14px;
        color: #828282;
        margin-bottom: 20px;
      }
    }
    >div {
      display: flex;
      @media (max-width: 425px) {
        flex-direction: column;
        align-items: flex-start;
        >a:first-child {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
      >a {
        text-decoration: none;
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: $border;
        &:first-child {
          margin-right: 10px;
        }
        .documentation-icon {
          width: 16px;
          height: 20px;
          background-image: url("./img/pdf-icon.svg");
          margin-right: 10px;
        }
        span {
          color: #F74623;
        }
      }
    }
  }
}

.quote-item {
  display: flex;
  padding: 0px 0px 20px 10px;
  box-sizing: border-box;
  align-items: center;
  margin-bottom: 20px;

  @media(max-width: 1115px) {
    max-width: 654px;
    .quote-item__info {
      width: 330px;
    }
  }

  @media(max-width: 900px) {
    padding: 0px 0px 20px 10px;
    max-width:900px;
  }
  @media(max-width: 600px) {
    flex-wrap: wrap;
    max-width: 500px;
    margin: 0 auto;
  }


  .quote-item__img {
    margin-right: 24px;
  }

  .quote-item__info {
    width: 480px;
    margin-top: 10px;
    h4{
      font-size: 16px;
      width: 100%;
    }
    @media(max-width: 940px) {
      width: 280px;
    }
    @media(max-width: 940px) {
      width: 80%;
    }


    .delivery-info {
      margin: 10px 0 0 0;
      display: flex;
      flex-wrap: wrap;
      color: rgba(130, 130, 130, 1);
    }

    .delivery-info li {
      list-style: none;
      display: block;
      background: transparent url('./img/point-gray.svg') right center no-repeat;
      padding-right: 10px;
      margin-right: 10px;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 10px;
    }

    .delivery-info li:last-child {
      background: none;
    }

    .delivery-info li:first-child {
      list-style: none;
    }

    p {
      color: rgba(130, 130, 130, 1);
      margin-bottom: 10px;
    }

  }

  .quote-item__price {
    display: flex;

    div:first-child {
      margin-right: 40px;
    }

    div {
      min-width: 100px;

      p {
        text-align: right;
        color: #828282;
      }

      p:first-child {
        color: #333333;
        font-weight: 500;
      }
    }
  }

  .quote-item__amount {
    margin-left: auto;

    p {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
    }
  }

  .cross-btn {
    margin: 0 20px;
    background: url("./img/cross.svg");
    min-width: 20px;
    height: 20px;
    cursor: pointer;
    @media(max-width: 600px) {
      margin-left: auto;
    }

  }

  .plus-icon {
    background: url("./img/plus-orange.svg");
    min-width: 30px;
    height: 30px;
    display: none;
    margin: 0 15px;
    cursor: pointer;

  }
}
.quote-item.awaiting{
  max-width: 800px;
}
.horizontal-line {
  width: 93%;
  height: 1px;
  border-bottom: 1px solid #E7E8EA;
  margin-bottom: 20px;
}

.quote__total-price {
  display: flex;
  margin-left: auto;
  width: 300px;
  padding-right:30px;

  div:first-child {
    margin-right: 40px;
  }

  div {
    min-width: 100px;

    p {
      text-align: right;
      color: #828282;
      margin-bottom: 20px;
    }
  }

  div:last-child {
    p {
      color: #333333;
      font-weight: 500;
    }
  }
}

.quote-item.deleted {
 .quote-item__info,.quote-item__price,.quote-item__img{
   opacity: 0.4;
 }

  .cross-btn {
    display: none;
  }

  .plus-icon {
    display: block;
  }
}

.quote__info {
  width: 500px;
  margin: 0px 20px 0px 80px;
  padding-left: 20px;
  @media(max-width: 1280px) {
    max-width: 360px;
    margin: 0px 20px 0px 60px;
  }
  @media(max-width: 1180px) {
    max-width: 320px;
    margin: 0px 20px 0px 0px;
  }
  @media(max-width: 900px) {
    min-width: 100%;
    padding-left: 0;
  }

}

.quote__request {
  box-sizing: border-box;
  padding: 30px 0px 20px 20px;
  background: #FFFFFF;

  a {
    text-decoration: none;
  }

  button {
    display: block;
    margin-top: 20px;
  }

  .request__text {
    font-size: 13px;
    line-height: 15px;
    color: rgba(130, 130, 130, 1);
    margin: 6px 0px 0 0px;
  }
}

.quote__state {

  div {
    box-sizing: border-box;
    width: 100%;
    height: 100px;
    background: rgba(242, 242, 242, 1);
    padding: 30px 0px 30px 20px;
    margin-bottom: 10px;

    p:first-child {
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #333333;
      margin-bottom: 6px;
    }

    p:last-child {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 15px;
      color: #777777;
    }
  }
}

.messenger {
  max-width: 800px;
  margin: 60px 20px 30px 0px;
  border: 1px solid #E7E8EA;
  @media(max-width: 1440px){
    margin: 60px 20px 30px 20px;
  }
  .messenger__header {
    position: relative;
    border: 1px solid #E7E8EA;
    background: #F2F2F2;
    display: flex;
    align-items: center;
    padding: 20px;
    justify-content: space-between;

    div {
      p:first-child {
        line-height: 19px;
        color: #333333;
        font-weight: 500;
        margin-bottom: 6px;
      }
      .receiver__name,.order__name{
        line-height: 19px;
        color: #333333;
        font-weight: 500;
        margin-bottom: 6px;
      }

      p:last-child {
        font-size: 13px;
        line-height: 15px;
        color: #777777;
      }
    }

    .order {
      p{
        text-align: right;
      }
    }

  }

  .messenger__body {
    padding: 20px;
    background: #FFFFFF;

    .user-message {
      padding: 20px 0px;
      width: 100%;

      p {
        text-align: right;
      }
    }

    .receive-message {
      padding: 20px;
      background: rgba(252, 238, 227, 1);
    }

    .username {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #333333;
      margin-bottom: 15px;
    }

    .attached-files {
      max-width: 180px;
      box-sizing: border-box;
      justify-content: space-between;
      border: 1px solid rgba(0,0,0,0.1);
      padding: 20px;
      margin: 15px 0px;
      display: flex;

      .file__img {
        background: url("./img/pdf-icon.svg");
        width: 15px;
        height: 20px;
      }

      .file__name {
        line-height: 19px;
        color: #F74623;
      }
    }

    .text {
      font-weight: normal;
      margin-bottom: 15px;
    }

    .date {
      font-size: 13px;
      line-height: 15px;
      color: #777777;
    }
  }

  .input__container {
    padding: 16px 20px;
    display: flex;
    align-items: center;
    border: 1px solid #E7E8EA;
    border-left: none;
    justify-content: space-between;
    background: #FFFFFF;


    & > div {
      display: flex;
      width: 85%;
      align-items: center;
      @media (max-width: 500px) {
        width: 100%;
        margin-bottom: 20px;
      }
    }

    input {
      max-width: 497px;
      height: 20px;
      border: none;
      font-size: 16px;
      line-height: 19px;
      margin-left: 19px;
      margin-right: 45px;
      text-overflow: ellipsis;

      &[type="file"] {
        display: none;
      }

      @media (max-width: 800px) {
        width: 90%;
      }
      @media (max-width: 500px) {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}

.modal-payments-pending {
  .modal__container {
    width: 800px;
    background-color: #ffffff;
    @media (max-width: 800px) {
      width: 100%;
    }
    .modal-header {
      padding: 30px 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    button, >p {
      margin: 40px 0 30px 40px;
      margin-left: 40px;
      margin-bottom: 30px;
    }
  }
  .wire__container {
    padding: 0 40px;
    border: $border;
    margin: 0 40px;
    >p {
      font-size: 16px;
      line-height: 19px;
      white-space: pre-wrap;

      &:last-child {
        margin: 20px 0;
        >span {
          font-weight: 700;
        }
      }
    }
    .wire-item__title {
      margin: 30px 0 10px;
      color: #828282;
      font-size: 16px;
    }
  }
} 