.name__wrapper {
  padding: 60px 0px 30px 20px;
  margin: 0 auto;
  max-width: 1400px;
}

.cart {
  display: flex;
  background: rgba(251, 251, 253, 1);
  padding-top: 40px;
  margin: 0 auto 20px auto;

  max-width: 1440px;
  box-sizing: border-box;

  @media(max-width: 800px) {
    display: block;
    padding: 10px;
  }
}

.interval {
  width: 100%;
  display: none;
}

.marketplaceBtn {
  display: none;
}

.cart__content {
  width: 836px;
  box-sizing: border-box;
  @media(max-width: 1115px) {
    width: 100%;
  }


}

.quotation-request {
  padding-left: 20px;

  p {
    margin-bottom: 40px;

    a {
      color: rgba(247, 70, 35, 1);
      text-decoration: none;
      font-weight: bold;
      cursor: pointer;
    }
  }

  .suppliers-info {
    font-style: italic;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
  }

  .button-secondary, .button-message {
    display: block;
    margin-bottom: 30px;
  }


}

.cart__info {
  width: 500px;
  margin: 0px 20px 0px 100px;
  @media(max-width: 1280px) {
    max-width: 360px;
    margin: 0px 20px 0px 60px;
  }
  @media(max-width: 1180px) {
    max-width: 320px;
    margin: 0px 20px 0px 0px;
  }
  @media(max-width: 900px) {
    min-width: 260px;
  }
  @media(max-width: 800px) {
    min-width: 100%;
  }

}

.name__wrapper.empty {
  padding-left: 0;

  .name-empty-cart {
    display: block;
    max-width: 420px;
    margin: 0 auto;
  }
}
//class is added to create empty cart page
.cart.empty {
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 30px;

  .cart__info {
    margin-left: 0;

    .cart__instruction {
      margin-top: 0;

      .instruction__title {
        cursor: default;

        .arrow-down {
          display: none;
        }
      }
    }

    @media(min-width: 500px){
      max-width: 500px;
    }
  }

  .marketplaceBtn, .interval {
    display: block;
    margin-top: 30px;
  }

}

.cart__quotation-request {
  box-sizing: border-box;
  padding: 30px 0px 20px 20px;
  background: #FFFFFF;

  .quotation-request__text {
    font-size: 13px;
    line-height: 15px;
    color: rgba(130, 130, 130, 1);
    margin: 6px 0px 20px 0px;
  }
}

.cart-item {
  display: flex;
  padding: 0px 20px 20px 10px;
  box-sizing: border-box;
  align-items: center;
  margin-bottom: 20px;

  .cart-item__img {
    margin-right: 24px;
  }

  .cart-item__info {
    width: 480px;
    margin-top: 10px;


    .delivery-info {
      display: flex;
      flex-wrap: wrap;
      color: rgba(130, 130, 130, 1);
    }

    .delivery-info li {

      font-size: 16px;
      line-height: 19px;
      list-style: none;

      background: transparent url('./img/point-gray.svg') right 2px center no-repeat;
      background-size: 5px;

      padding-right: 16px;
      margin-right: 10px;
      margin-bottom: 10px;


    }

    .delivery-info li:last-child {
      background: none;
    }

    @media(max-width: 1115px) {
      width: 330px;
    }
    @media(max-width: 940px) {
      width: 280px;
    }
    @media(max-width: 800px) {
      width: 380px;
    }
  }

  .cart-item__counter {
    padding: 16px 30px;
    width: 174px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #E7E8EA;
    background: #FFFFFF;

    .minus-icon {
      background: url("./img/minus-icon.svg") no-repeat;
      min-width: 12px;
      height: 2px;
      cursor: pointer;
    }

    .plus-icon {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      cursor: pointer;
      color: rgba(247, 70, 35, 1);
    }
  }

  .cross-btn {
    margin: 0 20px;
    background: url("./img/cross-btn-icon.svg") no-repeat;
    min-width: 21px;
    height: 21px;
    cursor: pointer;
    @media(max-width: 600px) {
      margin-left: auto;
    }
  }

  @media(max-width: 1115px) {
    max-width: 654px;
  }
  @media(max-width: 940px) {
    max-width: 600px;
    padding: 0px 0px 20px 10px;
  }
  @media(max-width: 865px) {
    max-width: 530px;
  }
  @media(max-width: 800px) {
    max-width: 100%;

  }
  @media(max-width: 660px) {
    flex-wrap: wrap;
    margin-right: 0;
  }
  @media(max-width: 576px) {
    .cart-item__counter, .cross-btn {
      margin-top: 25px;
    }
  }

}

.cart-item.disabled {
  .cart-item__img, .cart-item__info, .cart-item__counter {
    opacity: 0.3;
  }

  .cart-item__counter {
    padding-left: 80px;

    .minus-icon {
      display: none;
    }
  }
}

.cart__instruction {
  box-sizing: border-box;
  border: 1px dashed #E0E0E0;
  margin-top: 40px;
  width: 100%;
  padding: 20px 30px 20px 30px;
  background: #FBFBFD;

  .instruction__title {
    display: flex;
    align-items: center;

    p {
      color: #828282;
      line-height: 24px;
    }


    .arrow-down {
      background: url("./img/arrow-down.svg");
      width: 11px;
      height: 8px;
      float: right;
      margin-left: auto;
      cursor: pointer;
    }
  }

  .instruction__body {
    display: none;
    margin-top: 20px;
  }

  .instruction__body.active {
    display: block;

    .instruction__list {
      padding-left: 30px;

      .list-item {
        .list-item__title {
          margin-top: 15px;
          display: flex;

          span {
            color: rgba(247, 70, 35, 1);
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            margin-right: 15px;
          }

          p {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;

          }
        }

        .list-item__content {
          margin: 10px 0px 0px 20px;

          p {
            color: rgba(130, 130, 130, 1);
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            margin-left: 5px
          }
        }
      }

    }

    .instruction__list li {
      list-style: none;
    }
  }

  .instruction__link {
    margin: 20px 0px 0px 20px;
    display: flex;
    align-items: center;

    .link-arrow {
      background: url("./img/arrow-right-orange.svg");
      width: 20px;
      height: 20px;
      margin-left: 10px;
    }
  }

  .instruction__link a {
    text-decoration: none;
    color: rgba(247, 70, 35, 1);
  }

  .instruction__link a:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.question-icon {
  color: rgba(247, 70, 35, 1);
  border: 1px solid #E0E0E0;
  border-radius: 50px;
  padding: 3px 8px;
  margin-right: 12px;
}

.modal-contact-quotation {
  .modal__container {
    width: 800px;
    background-color: #ffffff;
    overflow: auto;
    max-height: 600px;
    @media (max-width: 800px) {
      width: 100%;
    }

    .modal-header {
      padding: 30px 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .request-button {
      margin: 40px 0px 40px 40px;
    }

    .shipping-info {
      background-color: #F2F2F2;
      padding: 30px 40px;

      .shipping-info__body {
        display: none;
      }

      .shipping-info__body.active {
        display: block;
      }
    ;

      .select {
        background: #FFFFFF;
      }

      .select__current {
        padding: 14px;
        color: rgba(51, 51, 51, 1);
        font-size: 16px;
      }

      .select__body {
        border: none;
      }

      .shipping-info__title {
        position: relative;
        display: flex;
        align-items: center;

        label {
          margin-right: 10px;
        }
      }

      .shipping-info__tooltip {
        position: absolute;
        right: 0;
        top: 10px;
        display: none;
        padding: 10px 20px;
        background: #FFFFFF;
        max-width: 200px;

        p {
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 15px;
        }
      }

      .shipping-info__tooltip.active {
        display: block;
      }

      .shipping-info__preference {
        p {
          margin: 40px 0px 20px 0px;
        }
      }

      .shipping-info__modes {
        p {
          margin: 40px 0px 20px 0px;
        }

        .modes {
          display: flex;
          max-width: 289px;
          justify-content: space-between;
        }
      }

      .shipping-info__shipping-place {
        p {
          margin: 40px 0px 20px 0px;
        }

        .reception-address {
          display: flex;
          justify-content: space-between;

          .select, input {
            width: 40%;
          }

          input {
            border: none;
            padding-left: 16px;
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }

    .input__container {
      margin: 30px 40px;
      padding: 30px 20px;
      display: flex;
      align-items: center;
      border: $border;
      @media (max-width: 500px) {
        flex-direction: column;
        align-items: flex-start;
      }

      & > div {
        display: flex;
        width: 85%;
        align-items: center;
        @media (max-width: 500px) {
          width: 100%;
          margin-bottom: 20px;
        }
      }

      input {
        width: 497px;
        height: 20px;
        border: none;
        font-size: 16px;
        line-height: 19px;
        margin-left: 19px;
        margin-right: 45px;

        &[type="file"] {
          display: none;
        }

        @media (max-width: 800px) {
          width: 90%;
        }
        @media (max-width: 500px) {
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }

  .modal__overlay {
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
  }
}
