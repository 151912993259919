@font-face {
  font-family:'Roboto';
  src: url('./fonts/Roboto Bold.eot');
  src: url('./fonts/Roboto Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Roboto Bold.woff2') format('woff2'),
    url('./fonts/Roboto Bold.woff') format('woff'),
    url('./fonts/Roboto Bold.ttf') format('truetype'),
    url('./fonts/Roboto Bold.svg#Roboto Bold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-2044;
}

@font-face {
  font-family:'Roboto';
  src: url('./fonts/Roboto Bold Italic.eot');
  src: url('./fonts/Roboto Bold Italic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Roboto Bold Italic.woff2') format('woff2'),
    url('./fonts/Roboto Bold Italic.woff') format('woff'),
    url('./fonts/Roboto Bold Italic.ttf') format('truetype'),
    url('./fonts/Roboto Bold Italic.svg#Roboto Bold Italic') format('svg');
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+0020-2044;
}

@font-face {
  font-family:'Roboto';
  src: url('./fonts/Roboto Italic.eot');
  src: url('./fonts/Roboto Italic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Roboto Italic.woff2') format('woff2'),
    url('./fonts/Roboto Italic.woff') format('woff'),
    url('./fonts/Roboto Italic.ttf') format('truetype'),
    url('./fonts/Roboto Italic.svg#Roboto Italic') format('svg');
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+0020-2044;
}

@font-face {
  font-family:'Roboto';
  src: url('./fonts/Roboto.eot');
  src: url('./fonts/Roboto.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Roboto.woff2') format('woff2'),
    url('./fonts/Roboto.woff') format('woff'),
    url('./fonts/Roboto.ttf') format('truetype'),
    url('./fonts/Roboto.svg#Roboto') format('svg');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-2044;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html {
  font-family: 'Roboto';
}

h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 52px;
  line-height: 52px;
}

h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 52px;
}

h3 {
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 130%;
}

h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 130%;
}

p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}
    