.info-page__container {
  &.info-page__container_contact_us {
    max-width: 100%;
  }
  .contact-info__wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 1000px) {
      flex-direction: column;
    }

    .map__container {
      width: 800px;
      height: 400px;
      margin-right: 40px;

      @media (max-width: 1000px) {
        width: 100%;
      }
    }

    .contact-info__container {
      h3 {
        color: #333333;
        margin-bottom: 20px;

        &:last-of-type {
          margin-top: 40px;
        }
      }
      .contact__item {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
      }
      .contact-icon__container {
        margin-right: 14px;
        .map-icon {
          width: 20px;
          height: 20px;
          background-image: url("./img/map-icon.svg");
        }

        .email-icon {
          width: 20px;
          height: 20px;
          background-image: url("./img/email-icon.svg");
        }

        .tel-icon {
          width: 20px;
          height: 20px;
          background-image: url("./img/phone-icon.svg");
        }
      }

      .social__links {
        display: flex;
        width: 56px;
        justify-content: space-between;
        align-items: center;

        .youtube-icon {
          width: 20px;
          height: 20px;
          background-image: url("./img/youtube-red.svg");
        }

        .linkedin-icon {
          width: 20px;
          height: 20px;
          background-image: url("./img/linkedin-red.svg");
        }
      }
    }
  }

  .question__container {
    margin: 60px 0 40px;
    width: 800px;

    @media (max-width: 1000px) {
      width: 100%;
    }

    >p {
      margin-bottom: 40px;
    }

    form {
      background-color: #ffffff;
      padding: 30px 40px;

      @media (max-width: 425px) {
        padding: 30px 20px;
      }

      >div {
        margin-bottom: 20px;
      }

      .button__container {
        margin-top: 28px;
        display: flex;
        align-items: center;

        input[type="file"] {
          display: none;
        }

        .question-icon {
          position: relative;
          color: #F74623;
          border: $border;
          width: 18px;
          height: 18px;
          font-size: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          padding: 0;
          margin-right: 0;
          margin-left: 12px;
          cursor: default;
    
          &:hover .question-tooltip {
            display: block;
          }

          .exclamation-sign {
            width: 2px;
            height: 10px;
            background-image: url("./img/exclamation-mark.svg");
          }
    
          .question-tooltip {
            display: none;
            position: absolute;
            font-size: 13px;
            line-height: 15px;
            color: #777777;
            background-color: #ffffff;
            width: 200px;
            padding: 10px 20px;
            margin: 0;
            box-shadow: 0px 20px 50px 0px #0000001A;
            left: 30px;
            top: -10px;
            white-space: pre-line;
          }
        }
      }

      .custom-select {

        .select-selected {
          border: $border;
          width: 270px;
          padding: 16px 14px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #828282;

          @media (max-width: 425px) {
            width: 216px;
          }

          &.select-open {
            border-color: #828282;
          }

          &:hover {
            border-color: #BDBDBD;
          }
        }

        .select-icon {
          width: 11px;
          height: 7px;
          background-image: url("./img/select-arrow.svg");
        }
      }

      .form-item {
        width: 100%;
        input {
          width: 282px;

          @media (max-width: 425px) {
            width: 228px;
          }
        }

        textarea {
          width: 612px;

          @media (max-width: 1000px) {
            width: 500px;
          }

          @media (max-width: 670px) {
            width: 100%;
          }
        }
      }

      .checkbox__container {
        align-items: flex-start;
      }
    }
  }
}