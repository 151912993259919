.info-page__container {
  color: #333333;
  max-width: 800px;
  padding: 60px 60px 60px 100px;

  @media (min-width: 1700px) {
    padding: 60px 260px;
  }

  @media (max-width: 1280px) {
    padding: 60px;
  }

  @media (max-width: 800px) {
    padding: 60px 40px;
  }

  @media (max-width: 500px) {
    padding: 60px 20px;
  }

  h1 {
    margin-bottom: 60px;
  }

  h2 {
    margin-top: 60px;
  }

  h3, h4 {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .etc {
    font-size: 18px;
    line-height: 23px;
    margin-top: 30px;
  }

  .text__row {
    display: flex;
    margin-bottom: 20px;

    @media (max-width: 370px) {
      flex-direction: column;
    }

    >h4:first-child, >p:first-child {
      width: 300px;
      flex-shrink: 0;

      @media (max-width: 650px) {
        width: 200px;
        margin-right: 15px;
      }

      @media (max-width: 450px) {
        width: 150px;
      }

      @media (max-width: 370px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  }

  a {
    text-decoration: none;
    color: #F74623;
  }

  .image-404__container {
    margin-top: 80px;
  }

  .info-404 {
    margin: 80px 0;
  }

  .buttons-links__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    >a {
      margin-bottom: 30px;
    }
  }
}