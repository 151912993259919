main.auth__container {
  @media (min-width: 1810px) {
    max-width: 100%;
    margin: 80px 0 0;
    display: flex;
    justify-content: center;
  }
}

.authentication-page__wrapper {
  display: flex;
  margin-top: -80px;
  justify-content: flex-end;
  overflow-y: auto;
  @media (min-width: 1810px) {
    width: 1918px;
    position: relative;
    height: 100vh;
    overflow-y: hidden;
  }

  @media (max-width: 1140px) {
    justify-content: flex-start;
  }

  .background__wrapper {
    width: 50%;
    height: 100vh;
    max-height: 1000px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: fixed;
    left: 0;

    @media (min-width: 1440px) {
      justify-content: flex-start;
    }

    @media (min-width: 1810px) {
      // position: relative;
      position: absolute;
      max-width: 960px;
      height: 100%;
    }

    @media (max-width: 1140px) {
      display: none;
    }

    &.background__wrapper_register-page {
      background-color: #619FE7;
    }

    &.background__wrapper_login-page {
      background-color: #F75E23;
    }

    .background-pattern {
      position: absolute;
      width: 904px;
      height: 678px;
      background-size: cover;
      z-index: 0;
      top: 0;
      right: 0;

      &.background-pattern_register-page {
        background-image: url("./img/register-pattern.png");
      }

      &.background-pattern_login-page {
        background-image: url("./img/login-pattern.png");
      }
    }

    .text__container {
      z-index: 1;
      color: #ffffff;
      width: 480px;
      margin-bottom: 40px;

      @media (min-width: 1440px) {
        margin-left: 100px;
      }

      @media (min-width: 1700px) {
        width: 680px;
      }

      @media (min-height: 1000px) {
        margin-bottom: 120px;
      }

      p {
        color: #ffffff;

        &:last-of-type {
          margin: 30px 0 40px;
          border-bottom: 1px solid #ffffff;
          padding-bottom: 40px;
        }
      }

      .title {
        font-size: 30px;
        line-height: 39px;

        @media (min-width: 1700px) {
          width: 451px;
        }
      }

      ul {
        font-size: 18px;
        line-height: 24px;
        margin-left: 25px;
        >li {
          margin-bottom: 24px;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .form__wrapper {
    padding: 100px 0 70px;
    width: 50%;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 1810px) {
      max-height: 1000px;
      overflow-y: auto;
      align-items: flex-start;
      margin-left: 160px;
    }

    @media (max-width: 1140px) {
      width: auto;
      padding: 100px 60px 70px;
    }

    @media (max-width: 500px) {
      padding: 100px 20px 70px;
    }

    .logo-img {
      width: 260px;
      height: 53px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
      // background-image: url("./img/logo.png");
    }

    .logo__container {
      width: 520px;

      @media (max-width: 640px) {
        width: 100%;
      }

      @media (min-width: 1810px) {
        margin-left: 160px;
      }
    }

    h2 {
      width: 520px;
      padding-top: 80px;
      padding-bottom: 40px;
      color: #333333;

      @media (max-width: 640px) {
        width: 100%;
      }
    }

    .form__container {
      width: 520px;

      @media (min-width: 1810px) {
        margin-left: 160px;
      }

      @media (max-width: 640px) {
        width: 100%;
      }

      .radio__wrapper {
        >span {
          font-size: 16px;
          line-height: 19px;
          color: #828282;
        } 
        .radio__container {
          margin-top: 20px;
          width: 175px;
          display: flex;
          justify-content: space-between;

          >label {
            font-size: 16px;
            line-height: 24px;
            &::before {
              width: 22px;
              height: 22px;
              margin-right: 16px;
              background-size: auto;
            }
          }
        }
      }

      .form-item {
        margin-top: 20px;
      }

      .checkbox__container {
        margin-top: 20px;

        >label {
          font-size: 16px;
          line-height: 24px;
          &::before {
            width: 22px;
            height: 22px;
            margin-right: 16px;
            background-size: auto;
          }
        }

        .custom-checkbox + label {
          align-items: flex-start;
        }
      }

      .button-primary {
        margin: 40px 0;
      }
    }

    .link__container {
      display: flex;
      flex-wrap: wrap;
      p {
        font-size: 18px;
        line-height: 24px;
        color: #828282;
        margin-right: 4px;
        margin-bottom: 10px;
      }

      a {
        font-size: 18px;
        line-height: 24px;
        color: #F74623;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}